<template>
  <v-bottom-sheet v-model="sheet" eager inset scrollable max-width="500" content-class="zn-popup">
    <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
      <div class="d-flex justify-center">
        <v-spacer></v-spacer>
        <span class="text-body-1 font-weight-bold black--text pa-4">서비스 이용약관</span>
        <v-spacer></v-spacer>
        <v-icon color="black" class="pr-2" @click="sheet = false">mdi-close</v-icon>
      </div>
      <div class="pl-4 pr-4 text-left overflow-y-auto mb-4" style="max-height: 500px">
        <div v-once>
          제1장 총칙<br/>
          <br/>
          제1 조 (목적)<br/>
          <br/>
          본 약관은 0.8L(공팔리터) 서비스(이하 ‘서비스’)를 제공 및 운영하는 주식회사 공팔리터가(이하 ‘회사’)가 제공하는 서비스를 이용함에 있어, 회사, 서비스에 가입한 회원, 그리고 서비스의
          기준에 부합하는 상품을 유통, 판매, 제조하는 기업인 판매자 간의 제반 권리 의무와 관련 절차 등을 규정하는데 그 목적이 있습니다.<br/>
          <br/>
          제2 조 (용어의 정의)<br/>
          <br/>
          본 약관에서 사용하는 용어의 정의는 다음 각 항과 같으며, 정의되지 않은 용어에 대한 해석은 관계법령 및 홈페이지 내 별도 안내에서 정하는 바에 따릅니다.<br/>
          <br/>
          서비스: 회사가 제공하는 공팔리터 서비스를 의미합니다. 공팔리터는 “0.8L”라고도 표기할 수 있습니다. 공팔리터 서비스는 단말기(모바일, PC 등의 장치를 포함)를 이용하는 모든 회원이 이용하는
          온라인 광고 및 정보제공, 중개판매서비스로, 회사 또는 판매자가 상품(이하에서 정의됨)을 수령할 회원의 범주를 결정하면 회원은 회사가 제시하는 절차(리뷰 작성 등)를 이행하는 전제하에 그에 대한
          보상으로 판매자의 상품을 무료 또는 경험가로 제공받는 서비스를 의미합니다.<br/>
          회원: 회원이라 함은 회사에 개인정보를 제공하여 회원가입을 한 자로서, 회사의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
          판매자: 서비스에서 제공하는 플랫폼을 통해 정보 및 광고 컨텐츠를 송출하고 회원에게 상품을 제공하거나 상품을 판매하는 브랜드 및 업체를 의미합니다.<br/>
          캠페인: 상품의 리뷰어를 모집하기 위한 회사의 일련의 활동을 말합니다. 캠페인은 아래 두 가지 방법으로 진행합니다.<br/>
          ① 무료신청(FREE) : 72시간동안 상품을 무료 체험할 리뷰어를 모집합니다. 리뷰어는 캠페인 종료 후 서비스 자체 알고리즘에 따라 자동으로 선정되며, 선정된 회원은 상품에 대한 리뷰를 작성하고
          SNS채널에 3개월간 전체공개로 공유하는 조건으로 상품을 제공받습니다.<br/>
          ② 즉시구매(NOW) : 72시간동안 상품을 경험가격에 유상 체험할 리뷰어를 모집합니다. 캠페인 기간내 신청한 회원은 모두 리뷰어로 선정되며, 상품에 대한 리뷰를 작성하고 SNS채널에 3개월간
          전체공개로 공유하는 조건으로 상품을 제공받습니다.<br/>
          리뷰어: 캠페인을 신청한 후 본 약관에 따라 회사에 의해 선정되어 상품(이하에서 정의됨)을 제공받고 그 조건으로 상품에 대한 리뷰를 작성, 공유하는 회원을 말합니다.<br/>
          게시글: 캠페인과 관련하여, 회원이 서비스를 이용함에 있어 게시 또는 등록하는 부호, 문자, 음성, 음향, 영상, 이미지, 글 등의 형태 및 각종 파일과 링크 등을 의미합니다.<br/>
          리뷰: 리뷰어가 상품을 경험한 내용을 창작한 작품을 말합니다.<br/>
          상품: 무료신청(FREE), 즉시구매(NOW) 캠페인 서비스와 관련하여, 회원이 판매자 또는 회사로부터 유상으로 구매한 현물 상품, 샘플 또는 쿠폰 등을 의미합니다.<br/>
          리터: 회사에서 운영하는 포인트(리터)이며 리뷰에 후원 및 결제시 사용할 수 있는 결제수단을 말합니다.<br/>
          후원: 회원이 리뷰어가 작성한 리뷰를 지지하기 위하여 서비스 내에서 회사가 정한 방식으로 리뷰어에게 리터를 선물하는 행위를 말합니다.<br/>
          후원 수수료: 회원이 다른 회원의 리뷰에 리터 후원시, 일정 금액의 리터로 부과되는 수수료를 말합니다.<br/>
          <br/>
          제3 조 (약관의 효력 및 개정)<br/>
          본 약관은 서비스 초기화면 및 https://www.08liter.com (이하 ‘사이트’)에 게시하여 회원에게 공지하고, 회원이 서비스 회원가입시에 이에 대해 동의함으로써 효력이 발생합니다.
          다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
          회사는 회원이 본 약관에 동의하기에 앞서 본 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 회원이 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
          제공하여 회원의 확인을 구하고 있습니다.<br/>
          회사는 「전자상거래 등에서의 소비자 보호에 관한법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래 기본법」, 「전자서명법」, 「정보통신망 이용 촉진 및 정보 보호 등에 관한 법률」 등
          관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br/>
          본 약관은 필요 시 개정될 수 있으며 약관을 개정하고자 할 경우, 회사는 개정된 약관을 적용일자 및 개정사유를 명시하여 현행 약관과 함께 그 개정 약관의 적용일자 14일 이전부터 적용일자 전일까지
          공지합니다.<br/>
          전 항에도 불구하고, 회원에게 불리한 약관을 개정할 경우에는 그 개정 약관의 적용일자 30일 이전부터 적용일자 전일까지 공지하며, 이 경우 회사는 개정전 내용과 개정후 내용을 명확하게 비교하여
          회원이 알기 쉽도록 표시합니다.<br/>
          개정약관은 다음 각호에 규정된 방법 중 한 가지 이상의 방법으로 회원에게 명확하게 공지하도록 합니다.<br/>
          ① 사이트 내 게시<br/>
          ② E-mail 통보<br/>
          ③ SMS 통보<br/>
          ④ 회사가 정한 기타 공지 방법 등<br/>
          본 약관의 개정과 관련하여 이의가 있는 회원은 제 4항 또는 제5항의 공지기간 이내 회원탈퇴 및 거부의사를 표명하지 않으면 개정약관에 동의한 것으로 간주합니다. 단, 회원탈퇴시 2조 4항에 해당되는
          상품을 제공받고 그 의무를 다하지 않았을 경우에는 의무를 다 할 때까지 회원의 자발적인 탈퇴가 불가합니다.<br/>
          회사가 약관을 개정한 경우 그 개정약관은 적용일자 이후에 체결되는 계약에만 해당되며, 이전에 체결된 계약에 대해서는 개정전의 약관으로 적용됩니다. 다만 이전에 계약을 체결한 회원이 개정약관의 적용을
          받기 원한다는 뜻을 제4항 또는 제5항에 의한 회사 공지기간 내에 회사가 동의를 받은 경우에는 개정약관이 적용됩니다.<br/>
          본 약관에서 정하지 아니한 사항과 본 약관의해석에 관하여는 「전자상거래 등에서의 소비자 보호에 관한 법률」, 「약관의 규제에 관한 법률」, 공정거래위원회가 고시하는 「전자상거래 등에서의 소비자보호
          지침」 및 관련법령 또는 상관례에 따릅니다.<br/>
          <br/>
          제4 조 (서비스의 제공 및 변경)<br/>
          회사는 다음과 같은 서비스를 제공합니다.<br/>
          ① 무료신청(FREE) 서비스: 판매자 또는 회사의 상품을 무상으로 제공, 이에 대한 리뷰를 작성할 리뷰어 선정, 판매자의 리뷰어에 대한 상품 배송 연계, 리뷰어가 제출하는 게시글의 관리
          등<br/>
          ② 즉시구매(NOW) 서비스: 판매자 또는 회사의 상품을 경험 가격으로 제공, 판매자의 리뷰어에 대한 상품 배송 연계, 리뷰어가 제출하는 게시글의 관리 등<br/>
          ③ 추천하기 서비스: 추천 가능 상품에 한하여 작성한 리뷰를 통해 지인에게 추천하고 그 지인이 해당 상품을 구매하면 구매액의 일정 비율을 등급별 적립율에 따라 리터로 돌려받는 보상프로그램<br/>
          ④ 기타 회사가 회원을 위하여 제공하는 서비스<br/>
          <br/>
          제5 조 (서비스의 중단)<br/>
          회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
          회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.<br/>
          제1항 및 제2항의 경우, 회사는 그 사유 및 기간 등을 사전 또는 사후에 회원에게 공지합니다.<br/>
          회사는 제1항 및 제2항의 경우 외에도 서비스의 원활한 수행을 위하여 필요한 기간을 정하여 사전에 공지하고 서비스를 중지할 수 있습니다.<br/>
          회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로써 회원 또는 제3자가 입은 손해를 배상합니다. 다만 회사의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br/>
          사업종목의 전환, 사업의 포기, 업체간의 통합 내지 합병 등으로 인하여 회사가 더 이상 서비스를 제공할 수 없게 되는 경우, 회사는 제8조에서 정한 방법으로 회원에게 통지하고 당초 회사에서 제시한
          보상기준에 따라 회원에게 보상합니다. 다만 회사가 보상기준 등을 제시하지 아니한 경우에는 회원의 마일리지 또는 적립금 등을 그에 상응하는 현물 또는 현금으로 회원에게 지급합니다.<br/>
          <br/>
          제6 조 (회원가입)<br/>
          회원은 회사가 정한 가입 양식에 따라 필수 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.<br/>
          회사는 다음의 각호 중 어느 하나에 해당하는 이용신청에 대해서는 승인을 하지 않거나, 승인을 취소할 수 있습니다.<br/>
          ① 가입 신청자가 이전에 회원자격을 상실한 적이 있는 경우. 다만 회원자격 상실 후 1년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.<br/>
          ② 타인의 명의를 도용하거나 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
          ③ 회원의 귀책사유로 인하여 승인할 수 없거나 기타 규정한 제반 사항을 위반하여 이루어진 신청<br/>
          ④ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우. 이 경우 회사는 회원가입 승인을 유보할 수 있습니다.<br/>
          ⑤ 필수 회원정보를 기입하지 아니한 경우<br/>
          회원가입은 회사의 승낙이 회원에게 도달한 시점에 이루어지는 것으로 합니다.<br/>
          회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 직접 온라인으로 개인정보를 수정하거나 즉시 전자우편 또는 기타 방법으로 회사에 그 변경사항을 알려야 합니다.<br/>
          <br/>
          제7 조 (회원탈퇴 및 자격상실)<br/>
          회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 이와 같은 요청을 받았을 경우 사이트를 통하여 회사가 고지한 방법에 따라 신속하게 회원탈퇴를 처리합니다. (단, 본조 제10조 7항 11에
          따라 회원의 의무를 다 하지 않았을 경우에는 탈퇴가 불가능하다.)<br/>
          회원이 본 약관에서 정한 의무를 위반한 경우 회사는 그 위반 정도에 따라 회원 계정에 대해 경고, 일부 또는 전체 서비스 이용 제한, 계약해지, 손해배상청구를 할 수 있습니다.<br/>
          회원이 서비스를 탈퇴한 경우 회원의 개인정보에 대한 보관 등은 회사의 개인정보처리방침에 따릅니다.<br/>
          회원탈퇴시 회원의 모든 혜택은 소멸됩니다. 회원탈퇴와 관련하여 발생한 손해는 해당 회원이 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다. 다만 그 손해가 회사의 고의 또는 과실로 인한
          경우에는 그러하지 아니합니다.<br/>
          <br/>
          제8 조 (회원에 대한 통지)<br/>
          회사는 회원이 회사와 미리 약정하여 지정한 전자우편 또는 SMS 등을 이용하여 회원에게 통지할 수 있습니다.<br/>
          회사는 불특정다수 회원을 위한 통지일 경우 1주일이상 서비스 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별
          통지합니다.<br/>
          회사는 회원의 고의적인 행동으로 인해 피해를 입었을 경우 전자우편 또는 SMS 등을 이용하여 회원에게 통지할 수 있으며, 이를 지속적으로 위반할 경우 개인정보를 이용해 내용증명을 보낼 수 있습니다.<br/>
          <br/>
          제9 조 (정보의 제공 및 광고의 게재)<br/>
          회사는 서비스 이용에 필요하다고 인정되는 다양한 정보 또는 설문조사 등을 공지사항 또는 전자우편, SMS 등의 방법으로 회원에게 제공할 수 있습니다. 다만 회원은 관련법령에 따른 거래관련정보 및
          고객문의 등에 대한 답변 등을 제외하고는 언제든지 이에 대해 수신거부를 할 수 있습니다.<br/>
          회사는 서비스 이용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 다만 광고가 게재된 전자우편 등을 수신한 회원은 그 광고에 대해 수신거부를 할 수
          있습니다.<br/>
          <br/>
          제10 조 (회원의 의무)<br/>
          회원은 서비스 신청, 이용 과정에서 다른 사람의 정보를 이용하거나 허위의 정보를 제공해서는 안됩니다.<br/>
          회원은 1인 1계정 사용을 원칙으로 하며, 특정 회원이 다수의 회원 계정을 고의적으로 생성한 후 활동하는 것이 적발될 시 서비스 이용이 제한될 수 있습니다.<br/>
          회원은 제3자의 ID를 이용하거나 자신의 ID를 제3자에게 이용하게 하여서는 안됩니다.<br/>
          회원은 회원의 정보가 변경된 경우에는 이를 회사에 즉시 알려야 하며, 회원의 변경 정보를 회사에 알리지 않아 발생하는 불이익에 대해 회사는 책임을 지지 않습니다.<br/>
          회원은 본 약관 기타 회사의 내부 규정 및 관계 법령을 준수해야 합니다.<br/>
          회원은 본 약관에서 규정하는 사항, 서비스 또는 각 연결 서비스별 홈페이지 상의 공지사항 및 회사가 정한 제반 정책이나 규정을 수시로 확인하여야 합니다.<br/>
          회원은 다음 각 호의 행위를 해서는 아니되며, 다음 각 호의 행위로 인해 회사에 손해가 발생한 경우, 회원은 회사에 대해 손해배상책임을 부담하게 됩니다.<br/>
          (손해배상금액의 범위는 해당 상품의 정상가와 배송비, 손해배상액을 청구 및 수취하는 과정에서 발생하는 모든 비용의 합계액 이내로 합니다. )<br/>
          ① 서비스를 이용하여 구입한 상품의 대금 또는 기타 서비스 이용과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 행위<br/>
          ② 빈번한 서비스 취소, 구매취소 누적 등으로 타 회원의 구매기회에 영향을 미치는 행위<br/>
          ③ 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br/>
          ④ 회사의 허락 없이 서비스를 이용하여 영업/광고 활동 등을 하거나 회사가 허락한 내용과 범위를 벗어난 영업/광고 활동 행위<br/>
          ⑤ 타인의 신용카드, 은행 계좌, 휴대폰 등을 무단으로 이용 또는 도용하는 행위<br/>
          ⑥ 회사 또는 타인의 명예를 손상시키거나 확인되지 않은 사실을 유포하는 행위<br/>
          ⑦ 타인의 지적재산권 등 권리를 침해하는 행위 및 회사의 업무 수행에 현저한 지장을 초래하는 행위<br/>
          ⑧ 선정적이고 음란한 내용 기타 공공질서 및 사회상규에 반하는 내용을 게시하는 행위<br/>
          ⑨ 무상 또는 경험 가격에 상품을 수령 후 리뷰작성 시, 리뷰어 신청 시 연동했던 SNS 계정이 아닌 다른 계정으로 리뷰를 작성하는 행위<br/>
          ⑩ 무상 또는 경험 가격에 제공 받은 상품 재판매·양도·임대 등 처분하는 행위<br/>
          ⑪ 무상 또는 경험 가격에 상품을 제공받고도 창작물 제출의 의무를 하지 않은 행위<br/>
          ⑫ 무상 또는 경험 가격에 상품을 제공받았으나 회사에 권고에도 불구하고 잠적하는 행위<br/>
          ⑬ 회사에서 제시한 서비스 이용 가이드 정책을 위반(수정,삭제)한 경우<br/>
          회원은 서비스 이용 중 발견된 버그나 시스템오류 등의 문제점에 대해서 회사에 알려야 하며, 이를 다른 회원에게 전파하거나 악용해서는 안됩니다. 버그 및 시스템 오류 등의 문제가 발생한 후에도 이를
          회사에 알리지 않고 이득을 취하려는 목적으로 사용 시 버그 및 시스템 악용으로 간주되며, 해당 회원의 서비스 이용에 제한을 받을 수 있습니다.<br/>
          <br/>
          제11 조 (회원의 ID및 비밀번호에 대한 의무)<br/>
          ID 및 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 비밀번호변경이 가능하되, 회사가 요청하는 경우 회원은 본인 인증을 하거나 회사가 요구하는 본인
          확인 서류를 제출하여야 합니다.<br/>
          회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다. 회사는 회사의 귀책사유 없이 발생한 ID 및 비밀번호 공유 또는 도용으로 인한 불이익에 대해 책임지지 않습니다. 또한, ID
          및 비밀번호 공유 중에 제3자가 회원 본인의 ID를 이용하여 약관 위반행위가 발생한다고 하더라도 해당 회원의 서비스 이용이 제한될 수 있습니다.<br/>
          회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.<br/>
          <br/>
          제12 조 (회사의 의무)<br/>
          회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 운영하는데 최선을 다하여야 합니다.<br/>
          회사는 회원이 안전하게 인터넷 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
          <br/>
          제13 조 (개별 서비스에 대한 약관 및 이용조건)<br/>
          회사는 제공하는 서비스의 구체적 내용에 따라 개별 서비스에 대한 약관 및 이용조건을 개별서비스마다 별도로 정하여 회원의 동의를 얻을 수 있습니다. 이 경우 개별 서비스에 대한 이용약관 등이 본
          약관에 우선합니다.<br/>
          <br/>
          제14 조 (개인정보보호)<br/>
          회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.<br/>
          회원의 개인정보보호 및 사용에 대해서는 관계법령및 회사가 별도로 고지하여 회원의 동의를 얻는 개인정보처리방침이 적용됩니다.<br/>
          회사는 서비스 외에 서비스와 단순한 링크로 연결되어 제3자가 제공하는 서비스에 대하여는 회사의 개인정보처리방침이 적용되지 않으며, 회사는 위와 같은 제3자가 제공하는 서비스를 이용함으로써 발생하는
          회원의 손해에 대하여는 책임지지 않습니다.<br/>
          <br/>
          제15 조 (결제방법)<br/>
          회원이 서비스를 통해 유상으로 제공받은 상품 또는 구매한 상품에 대한 대금 결제는 다음 각 호의 방법에 따라 가능합니다. 회사는 회원의 지급방법에 대하여 상품의 대금에 어떠한 명목의 수수료도
          추가하여 징수할 수 없습니다.<br/>
          ① 직불카드, 신용카드 등의 각종 카드 결제<br/>
          ② 휴대폰 결제<br/>
          ③ 회사에서 운영하는 포인트(리터) 결제<br/>
          ④ 기타 전자적 지급방법에 의한 대금 지급 등<br/>
          회원이 대금 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.<br/>
          <br/>
          제16 조 (게시글의 관리)<br/>
          회사는 회원의 게시글을 소중하게 생각하며, 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다.<br/>
          회사는 다음 각호에 해당하는 게시글이나 자료는 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.<br/>
          ① 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용<br/>
          ② 특정 국적, 인종, 종교, 민족, 성별, 장애 등을 일방적으로 조롱하거나 해를 끼치는 내용인 경우<br/>
          ③ 회원이 음란물을 게재하거나 음란사이트를 링크한 경우<br/>
          ④ 회사 또는 제3자의 저작권 등 기타 지적재산권을 포함한 일체의 권리를 침해하는 내용인 경우<br/>
          ⑤ 게시판의 성격에 부합하지 않는 게시글의 경우<br/>
          ⑥ 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우<br/>
          ⑦ 영리를 목적으로 하는 광고성 내용인 경우<br/>
          ⑧ 범죄와 결부된다고 객관적으로 인정되는 내용일 경우<br/>
          ⑨ 회사에서 규정한 게시글 원칙에 어긋나거나 게시판 성격에 부합하지 않는 경우<br/>
          ⑩ 회사나 서비스의 정상적인 운영을 방해하는 경우<br/>
          ⑪ 기타 관계법령에 위배된다고 판단되는 경우<br/>
          ⑫ 사회적인 이슈로 상품에 문제가 생겼다 판단되는 경우<br/>
          ⑬ 상품 제공자가 회원의 게시글로 인해 재산상의 손해를 입었다 판단되어 요청하는 경우<br/>
          게시글의 내용이 「정보통신망 이용촉진 및 정보보호등에 관한 법률」, 「개인정보 보호법」, 「저작권법」 등에 위반되는 경우, 이로 인하여 권리가 침해된 자는 관련법령과 고객센터에서 정한 절차에 따라
          당해 게시글의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법령에 따라 필요한 조치를 취하여야 합니다.<br/>
          회사는 전항에 따른 요청이 없는 경우라도, 제3자의 권리가 침해되었거나 침해될 가능성이 있는 때, 또는 기타 회사 정책 및 관련법령에 위반된다고 인정될 만한 사정이 존재하는 때에는 관련법령에 따라
          해당 게시글의 게시중단 및 삭제 등 필요한 조치를 취할 수 있습니다.<br/>
          회원은 서비스를 탈퇴한 이후에는 기존에 작성한 게시글의 편집이 불가하므로, 탈퇴 이전에 해당 게시글에 대한 조치를 취하여야 합니다.<br/>
          <br/>
          <br/>
          제2장 캠페인 서비스<br/>
          제17 조 (제2장의 적용범위)<br/>
          본장은 캠페인 서비스에 대하여 적용합니다.<br/>
          <br/>
          제18 조 (서비스 이용원칙)<br/>
          회사는 판매자가 제공하는 상품에 대한 정보를 제공하거나, 판매자가 상품을 회원(리뷰어)에게 제공 및 배송할 수 있도록 연계하는 서비스를 제공함에 그치며, 이와 관련하여 판매자 또는 회원(리뷰어)을
          대리하지 않습니다.<br/>
          회사는 회원에게 제공하는 상품이 회원의 개별적 특성(신체적 특성 포함)에 적합하다거나 회원의 개별적 이용목적에 부합할 것이라는 점, 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한
          비침해성, 판매자가 입력하거나 링크시킨 URL에 게시된 자료의 진실성 또는 적법성 등 일체에 대하여 어떠한 보증도 하지 않습니다.<br/>
          회사가 회원에게 제공하는 정보서비스 등 용역중에서 재화의 판매자가 직접 회사에 제공한 정보와 관련하여 회원이 입을 수 있는 손해 일체, 회원이 당해 재화를 사용함으로 인하여 입을 수 있는 손해
          일체에 대한 책임은 당해 상품을 제공한 판매자에 있으며, 이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.<br/>
          <br/>
          제19 조 (캠페인 신청)<br/>
          회원은 다음 또는 이와 유사한 방법에 의하여 캠페인을 신청하며, 회사는 회원이 캠페인 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.<br/>
          ① 상품의 검색 및 선택<br/>
          ② 성명, 주소, 전화번호, 전자우편주소, 핸드폰번호, 사이즈등의 입력/선택란<br/>
          ③ 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
          ④ 본 약관에 동의하고 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스클릭)<br/>
          ⑤ 상품의 신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의<br/>
          회원은 캠페인을 이용함에 있어 리뷰 작성 의사 없이 캠페인에 신청을 하여서는 아니되며, 이를 통하여 다른 회원의 캠페인 이용 기회를 방해한 것이 적발되는 경우 회사는 해당 계정에 대하여 회원 자격을
          정지하는 등의 회사차원의 제재조치를 취할 수 있습니다.<br/>
          회원은 캠페인을 신청하기 전에 반드시 사이트 내에 작성한 거래의 조건을 정확하게 확인한 후 캠페인을 신청하여야 합니다. 캠페인의 신청 조건을 확인하지 않고 신청 및 이용으로 인하여 후 발생한 모든
          손실, 손해는 회원 본인에게 있습니다.<br/>
          캠페인 신청과 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.<br/>
          <br/>
          제20 조 (리뷰어 선정 기준)<br/>
          모든 회원은 서비스에서 운영하는 캠페인에 참여할 수 있으며, 아래 리뷰어 선정 기준에 따라 리뷰어로 선정될 수 있습니다.<br/>
          [리뷰어 선정 기준]<br/>
          SNS를 일상적으로 사용하는 유저를 대상으로 합니다. SNS를 일상적으로 사용하는 것을 판단하기 위해 회사는 아래와 같이 최소한의 선정 기준을 마련하였으며, 리뷰어는 해당 기준을 넘어서는 회원
          중에서 서비스 자체 알고리즘에 의해 랜덤으로 선정됩니다.<br/>
          (유의: 선정 기준은 회사의 정책에 의거 변경될 수 있으며, 변경될 시 별도 공지합니다.)<br/>
          o 캠페인 타겟에 해당될 경우 (회원정보 기준으로 누구나, 남자만, 여자만, 자녀있음만 등 캠페인 신청 타켓일 경우만 신청 가능)<br/>
          o 계정이용 제한을 받지 않았을 경우 (이용 제한을 받은 후 해제된 경우는 제외)<br/>
          o SNS(페이스북, 인스타그램, 네이버블로그, 웨이보, 샤오홍슈)가 비공개 또는 허위 계정이 아닐 경우<br/>
          o SNS(페이스북, 인스타그램, 네이버블로그, 웨이보, 샤오홍슈)가 경험후기(리뷰)외 일상관련 컨텐츠가 50%이상 계정인 경우<br/>
          o SNS(페이스북, 인스타그램, 네이버블로그, 웨이보, 샤오홍슈)가 회사에서 정한 등급기준에 맞는 계정일 경우<br/>
          <br/>
          제21 조 (리뷰어의 의무 및 리뷰어에 대한 제한 조치)<br/>
          리뷰어의 의무<br/>
          리뷰어는 회사에서 제공 받은 상품을 회원가입 및 캠페인 신청 시 기입한 자신의 SNS에 아래와 같은 의무사항을 유념하여 리뷰를 작성해야 합니다.(유의: 리뷰어의 의무는 당사의 정책에 의거 변경될 수
          있으며, 변경될 시 별도 공지합니다.)<br/>
          ① 리뷰마감일 내에 리뷰 작성을 완료합니다. (통상 배송받은 날로부터 일주일 이내, 리뷰어가 직접 방문하는 경우에는 방문 후 3일 이내)<br/>
          ② 인스타그램 리뷰작성 시 서비스에서 제공하는 해시태그를, 네이버블로그 리뷰작성 시 서비스에서 제공하는 검색키워드를 리뷰 내에 필수로 삽입합니다.<br/>
          ③ 인스타그램 리뷰작성 시 상품을 가장 잘 나타낼 수 있는 사진 또는 영상을 3개 이상 첨부, 네이버블로그 리뷰작성 시 사진 또는 영상을 10개 이상 첨부하고 사이트 내에 공지된 리뷰가이드 에 맞춰
          작성합니다.<br/>
          ④ 제공받은 상품은 공팔리터 리뷰 제출 시 공정위에서 제공하는 가이드에 맞춰 SNS계정에 협찬해시태그(인스타그램) 또는 스폰서배너(블로그)를 필수로 삽입해야 하며 임의 수정, 삭제
          불가합니다.<br/>
          ⑤ 작성한 리뷰 및 리뷰 SNS 공유 게시물을 서비스에서 확인할 수 있도록 리뷰마감일 내에 SNS공유 링크를 제출합니다.<br/>
          ⑥ 상품에 대한 리뷰 SNS 공유 게시물은 제출일로부터 3개월간 전체공개로 게시해야 합니다.<br/>
          ⑦ 위에 정의되지 않은 의무사항은 서비스 안내 또는 캠페인 가이드의 내용을 준수합니다.<br/>
          <br/>
          리뷰어의 의무 위반 사유<br/>
          리뷰어는 회사에서 정하는 조건에 따라 상품을 유 · 무상으로 지급 받을 수 있습니다. 단, 아래와 같은 위반사유가 발생한 경우 회사는 본조 제3항에 따른 제재 조치를 취할 수 있습니다.<br/>
          [위반 사유]<br/>
          ① 본조 제1항 ‘리뷰어의 의무’를 위반한 경우<br/>
          ② 캠페인 신청 시 배송 정보를 잘못 기재하거나 중복 또는 허위로 기재한 경우<br/>
          ③ 캠페인 신청 시, 허위 계정 또한 또는 비공개 계정을 사용한경우<br/>
          ④ 캠페인 신청 시, 경험후기(리뷰)외 일상관련 컨텐츠가 50%이상이 아닌 상업적영리가 목적인 계정을 사용한 경우<br/>
          ⑤ 리뷰어로 선정된 이후 회사에 알리지 않고 배송 정보를 변경하는 경우<br/>
          ⑥ 리뷰작성 기간 내 리뷰를 작성하지 않은 경우<br/>
          ⑦ 리뷰작성 및 링크 제출 후 3개월 이내 무단으로 삭제 또는 수정한 경우<br/>
          ⑧ 인스타그램 리뷰작성에 포함해야 하는 해시태그 및 콘텐츠(사진 또는 영상), 네이버블로그 리뷰작성에 포함해야 하는 검색키워드 및 콘텐츠(사진 또는 영상), 검색키워드, 스폰서배너 삽입이 잘못된
          경우<br/>
          ⑨ 회사에 알리지 않고 임의로 리뷰어 리뷰를 작성할 SNS를 변경하는 경우<br/>
          ⑩ 리뷰를 작성하더라도 리뷰어로 제공 받은 상품을 3개월 내에 재판매 · 양도 · 임대 등 처분하는 경우 (이 경우 리뷰어는 위약벌로써 상품 원가 및 배송비의 합산액을 회사에 대하여 지급하여야
          합니다.)<br/>
          ⑪ 본조 제1항 ‘리뷰어의 의무’를 다하지 않은 경우<br/>
          ⑫ 아래 '잘못된 리뷰'에 해당하는 리뷰를 작성한 경우<br/>
          [잘못된 리뷰]<br/>
          택배 또는 박스 리뷰<br/>
          상품을 받자마자 포장박스를 뜯지 않은 상태로 외관만 찍은 사진리뷰<br/>
          <br/>
          성의 없는 리뷰<br/>
          상품을 사용하지 않고, 받자마자 그 자리에서 작성한 인증샷과 같은 느낌의 리뷰<br/>
          상품을 바닥에 던져놓거나 기타 상품의 가치가 잘 나타나지 않는 등 무성의해 보이는 리뷰<br/>
          사진이 흐릿하거나,초점이 맞지 않거나, 잘린 사진을 올린 리뷰<br/>
          상품을 사용한 후의 경험 내용이 아닌 단순 기대평 또는 상품의 상세 설명을 복사, 나열하여 작성한 리뷰<br/>
          신청자 본인의 경험 내용이 아닌 타인에게 선물한 후기를 작성한 리뷰<br/>
          <br/>
          카테고리별 사진 정책에 어긋난 리뷰<br/>
          뷰티:기초 제품은 제품의 특성이 드러나지 않거나,색조 제품은 발색이 제대로 드러나지 않게 촬영한 경우<br/>
          패션:의류,액세서리,잡화를 직접 착용하지 않고 촬영한 경우<br/>
          식품:식품을 시식 또는 요리하지 않고 촬영한 경우<br/>
          유아:유아가 제품을 사용 또는 착용하지 않고 제품만 촬영한 경우<br/>
          라이프:제품을 사용 또는 착용하지 않고 촬영한 경우<br/>
          취미:해당 활동을 직접 체험하지 않은 상태에서 촬영하거나 체험과 무관한 사진을 촬영한 경우<br/>
          맛집(지역형):직접 방문하지 않은 상태에서 촬영하거나,매장에서 제공하는 서비스와 관련 없는 사진을 촬영한 경우<br/>
          뷰티,컬처(지역형):직접 방문하여 체험하지 않은 상태에서 촬영하거나 체험과 무관한 사진을 촬영한 경우<br/>
          기타:성별,연령 등 타겟팅 된 캠페인의 경우 해당 대상의 모습이 드러나지 않을 경우<br/>
          <br/>
          SNS공유 게시물 비공개 리뷰<br/>
          페이스북, 인스타그램, 네이버블로그, 웨이보, 샤오홍슈 등 SNS에 공유하는 리뷰 게시물이 비공개 또는 친구공개인 경우<br/>
          <br/>
          SNS공유 게시물을 기한 내 삭제한 리뷰<br/>
          SNS에 공유한 게시물의 3개월 유지 기간을 지키지 않고 삭제한 리뷰<br/>
          <br/>
          잘못 올라간 리뷰<br/>
          리뷰와 관련 없는 사진을 제출한 경우<br/>
          <br/>
          여러 상품을 한번에 게시한 리뷰<br/>
          1개 캠페인 상품에 해당하는 리뷰 작성이 원칙이나 동일브랜드 상품을 묶어 촬영 후 작성한 리뷰를 각 캠페인마다 동일하게 제출하는 경우<br/>
          <br/>
          지적재산권 위반 리뷰<br/>
          방송,음원,영화,만화 등 타인의 저작물을 당사자의 동의 없이 공유하는 경우<br/>
          타인의 권리에 속하는 상표권, 의장권, 저작권 등을 무단으로 침해하는 내용<br/>
          <br/>
          불법성 리뷰<br/>
          불법 사행성,도박 사이트를 홍보하는 경우<br/>
          불법 제품 또는 인터넷에서 판매금지된 상품을 판매하거나 홍보하는 경우<br/>
          범법행위에 대한 동기 부여 및 실행에 도움이 되는 정보를 제공하는 경우<br/>
          <br/>
          기타 잘못된 리뷰<br/>
          ‘첫 번째 당첨’, ‘두번째 선정’, ‘몇 번째 리뷰어 활동이다.’등 리뷰어 선정에 대한 내용을 남긴 경우<br/>
          상품의 체험과 관련된 내용 없이 단순히 해시 태그만 명시한 경우<br/>
          상품의 체험 관련 내용은 포함하였지만 가이드에서 제시한 필수 해시태그(인스타그램), 검색키워드 및 스폰서배너(네이버블로그) 같은 의무사항에 맞춰 기재하지 않은 경우<br/>
          상품과 관련 없는 글을 명시한 경우<br/>
          상품의 정당한 평가가 아닌 근거 없는 악평(비속어,비방어 등)을 남긴 경우<br/>
          관련 없는 특정 단어/문구를 반복 삽입하는 등의 비정상적인 경우<br/>
          스팸글, 홍보글 등의 자료를 리뷰로 올리는 경우<br/>
          타인의 개인정보 노출/유도, 관리자 사칭 등 기타 서비스를 본 서비스 제공 목적에 어긋나게 사용하기 위한 목적으로 리뷰를 작성한 경우<br/>
          <br/>
          리뷰어에 대한 제재 조치(경고, 서비스 이용 제한, 서비스 이용 영구 제한 및 손해배상청구, 이용 제한 해제)<br/>
          ① 경고(메시지 발송됨)<br/>
          o 리뷰지연시(마감일 경과시)<br/>
          o 제품과 무관한 리뷰 작성 시<br/>
          o 리뷰 작성 및 링크 제출 후 3개월 이내 리뷰 삭제 또는 리뷰 공개 상태를 비공개 (친구만보기)로 수정한 경우<br/>
          o 제출한 리뷰의 키워드, 문구, 태그, 사진, 공정위 협찬 표시 변경 시<br/>
          o 공정거래법에 의거한 상품협찬 문구 또는 이미지를 미삽입 하거나 삭제 했을 경우<br/>
          o 기타 본조 제1, 2항을 위반한 경우<br/>
          ② 서비스 이용 제한<br/>
          o 경고를 받은 즉시 서비스 이용이 제한되며, 아래 ④에 따라 이용 제한 해제가 이루어지기 전까지 모든 서비스 이용이 제한됩니다.<br/>
          ③ 서비스 이용 영구 제한(서비스 영구 이용 제한 시 메시지 발송됨) 및 손해배상청구(회사에 손해 발생한 경우)<br/>
          o 본조 1,2항을 위반하여 회사로 부터 시정요청을 받았으나, 시정하지 않은 경우<br/>
          o 특정 개인이 다수의 회원 계정을 고의적으로 생성하여 서비스 및 개인정보를 악용한 사례가 적발될 경우<br/>
          o 타인의 개인정보 노출/유도, 관리자 사칭 등 기타 서비스를 본 서비스 제공 목적에 어긋나게 사용한 경우<br/>
          o 제공받은 제품을 3개월 내에 재판매, 양도, 임대한 사례가 적발된 경우(이 경우 리뷰어는 위약벌로써 상품 원가 및 배송비의 합산액을 회사에 대하여 지급하여야 합니다.)<br/>
          ④ 서비스 이용 제한 해제 방법<br/>
          o 미작성, 미완료된 리뷰를 모두 작성 완료<br/>
          o 제품과 관련된 적합한 리뷰를 작성한 후 회사에 이용 제한 해제 요청<br/>
          o SNS 공유한 게시물을 전체공개로 전환한 후 회사에 이용 제한 해제 요청<br/>
          o 캠페인 가이드에 맞춰 수정한 후 회사에 이용 제한 해제 요청<br/>
          o 기타납득할 수 있는 타당한 사유를 회사에 전달했을 경우<br/>
          <br/>
          제22 조 (판매자의 권리와 의무)<br/>
          판매자의 권리<br/>
          판매자는 캠페인 서비스와 관련하여 아래와 같은 권리를 가집니다.<br/>
          ① 캠페인이 진행되는 내용을 판매자센터를 통해 언제든 확인할 수 있습니다.<br/>
          ② 캠페인진행 후 등록된 리뷰는 배송형은 송장등록일 이후 9일, 지역형은 쿠폰 사용일 이후 3일 뒤 확인이 가능하며, 결과리포트 확인 후 7영업일 이내에 회사 내 리뷰 검수팀을 통해 리뷰 재업로드
          또는 수정 요청들을 통해 재수정된 리뷰는 업데이트 될 수 있습니다.<br/>
          ③ 캠페인진행 후 등록된 리뷰는 마케팅 용도로 2차 활용할 수 있습니다. 단, 본 0.8L서비스를 통해 제공 받았음을 명시하여야 합니다.<br/>
          <br/>
          판매자의 권리제한<br/>
          판매자는 회사에서 제공하는 서비스를 통해 해당 상품의 캠페인을 진행할 수 있습니다. 단, 아래와 같은 사유가 발생한 경우 회사는 판매자의 서비스 이용에 제한을 둘 수 있습니다.<br/>
          ① 판매자가 제작, 판매, 판매 대행하는 상품이 아닌 경우 (판매자에게 상품 제공 또는 캠페인 진행에 대한 권리가 없을 경우)<br/>
          ② 성인용품, 음주류, 전자 담배 등 미성년자가 구매할 수 없는 상품인 경우<br/>
          ③ 서비스의 캠페인 진행 가능 상품 (뷰티, 패션, 라이프, 유아, 식품, 취미, 가전, 교육, 서비스, 지역형(맛집, 뷰티, 숙박, 건강, 컬처등) 카테고리 상품) 기준에서 벗어난 경우<br/>
          서비스는 아래와 같이 SNS 상의 유저들이 좋아할 만한 뷰티, 패션, 라이프, 유아, 식품, 취미, 가전, 교육, 서비스, 지역형(맛집, 뷰티, 숙박, 건강, 컬처 등) 상품만을 리뷰어 상품으로
          취급합니다. (리뷰어 상품 기준은 당사의 정책상 변경할 수 있으며, 변경 시 별도 공지합니다.)<br/>
          o 뷰티: 패키지 디자인이 예쁜 화장품, 대중이 선호하는 브랜드의 화장품, SNS로 표현하기 좋은 색조 화장품<br/>
          o 패션: 베이직한 아이템, 트렌디한 아이템, 유니크하면서 관심이 가는 아이템<br/>
          o 라이프: 디자인이 예쁜 인테리어 소품, 문구, 잡화, 식품, 생활 등의 아이템<br/>
          o 유아: 해당 대상이 사용하기에 적합하며, 안전하고 위생적이라고 인증받은 아이템<br/>
          o 식품: 식품위생법, 식약청 고시 등 관련법률에 따른기준에 위배되지 않은, 식용이 가능한 안전한 가공품<br/>
          o 취미: 미풍양속을 해치지 않고, 누구나 좋아할 만한 아이템 또는 서비스<br/>
          o 가전: 누구나 좋아할 만한 생활가전, 전자제품, IT제품 등의 아이템<br/>
          o 교육: 누구나 좋아할 만한 교육 수강권, 이용권<br/>
          o 서비스: 불법, 도박, 사행성에 위반되지 않는 플랫폼, 또는 웹 서비스<br/>
          o 맛집(지역형): 식품위생법, 식약청고시 등 관련 법령에 따른 기준에 위배되지 않은 음식<br/>
          o 뷰티(지역형): 안전하고 위생적인 장소이며, 누구나 좋아할 만한 뷰티 서비스<br/>
          o 숙박(지역형): 안전하고 위생적인 장소이며, 누구나 좋아할 만한 숙박 서비스<br/>
          o 건강(지역형): 안전하고 위생적인 장소이며, 불법적이지 않은 건강관련 서비스<br/>
          o 컬처(지역형): 안전하고 위생적인 장소이며, 미풍양속을 해치지 않고, 누구나 좋아할 만한 서비스<br/>
          ④ 불법 또는 사행성이 있는 성인을 대상으로 한 상품인 경우<br/>
          ⑤ 상품을 포함한 브랜드에 부정적인 이슈/여론이 발생한 경우<br/>
          ⑥ 판매자가 입력하거나 링크시킨 URL을 통해 상품에 대한 정보를 확인하기 어렵거나, 공신력없는 사이트로 연결될 경우<br/>
          ⑦ 판매자의 의무를 다하지 않거나 계약 내용을 이행하지 않은 경우<br/>
          <br/>
          판매자의 의무<br/>
          판매자는 회사에서 선정한 리뷰어에게 정해진 일자에 상품을 무상으로 배송(상품이 배송이 필요하지 않는 서비스인 경우 서비스 제공)하여야 하며, 리뷰어의 원활한 활동을 위해 리뷰어에게 발생한 문제를
          다음 각호에 따라 성실히 해결할 의무가 있습니다.<br/>
          ① 상품 파손, 오배송, 배송 누락 등 리뷰어에게 배송문제가 발생하면 판매자는 문제가 발생한 날로부터 3영업일 이내에 상품과 동일한 상품을 재배송해야 합니다. 위 기간 내 상품 배송이 이루어지지
          않을 경우, 판매자는 회사에 '상품수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          ② 지역형 캠페인을 진행하는 경우에는 유효기간 내에 서비스 제공 할 수 있도록 하여야 하며, 그 기간 내에 제공을 할 수 없을 시에 회사에 '상품수 x 상품가격’에 해당하는 금액을 위약벌로
          지급합니다<br/>
          ③ 지역형 캠페인을 진행하는 경우에는 리뷰어가 예약을 하고 방문하였음에도 불구하고 서비스를 제공하지 않을 경우 회사에 '상품수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          ④ 지역형 캠페인을 진행하는 경우에 사전 영업정보를 제대로 공개하지 않아 리뷰어에게 손해가 발생한 경우 회사에 '상품수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          ⑤ 캠페인을 진행하여 리뷰어 모집이 끝났음에도 불구하고 아무런 사유없이 일방적인 계약해제를 요구하는 경우 회사에 '상품수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          ⑥ 배송에 의해 취득한 리뷰어의 개인 정보를 1개월 이내에 폐기합니다.<br/>
          ⑦ 리뷰어의 개인 정보를 무단으로 이용하지 않습니다. 배송 목적을 제외한 판매자의 관리 소홀 및 부주의로 인한 회원의 개인정보 유출에 대한 피해 보상 책임은 판매자에게 있습니다. 판매자는 체험단
          진행 상품 수량 외에, 회사의 콘텐츠 제작 및 프로모션을 위한 상품을 최소 5개 이상(상품에 따라 협의 가능 / 동일 상품으로 2회 이상 진행 시 생략 가능) 회사에 제공합니다.<br/>
          ⑧ 캠페인 진행 당일 판매자에서 운영하는 SNS에 캠페인 내용 및 링크를 게시물로 공유합니다.<br/>
          ⑨ 즉시구매(NOW) 서비스는 캠페인이 진행되는 기일에 배송될 상품을 사전에 준비하며, 결제를 진행한 리뷰어에게 제품배송을 5영업일(단, 해외 배송은 예외) 이내에 상품 배송을 시작하고 판매자센터에
          운송장 정보를 등록합니다. 위 기간 내에 상품 배송이 이루어지지 않을 시, 판매자는 회사에 '배송 지연된 상품 수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          ⑩ 무료신청(FREE) 서비스는 캠페인이 진행되는 기일에 배송될 상품을 사전에 준비하며, 캠페인 종료 후 회사가 리뷰어를 회사의 시스템에 의해 자동 선정하고 이를 판매자에게 통지하면, 판매자는 그
          통지 받은 날로부터 5영업일(단, 해외 배송은 예외) 이내에 상품 배송을 시작하고 판매자센터에 운송장 정보를 등록합니다. 위 기간 내에 상품 배송이 이루어지지 않을 시, 판매자는 회사에 '배송
          지연된 상품 수 x 상품가격’에 해당하는 금액을 위약벌로 지급합니다.<br/>
          <br/>
          판매자 탈퇴시 브랜드 페이지의 유지<br/>
          회사는 판매자가 탈퇴한 후에도 판매자의 브랜드 페이지를 계속하여 게재할 수 있으며, 판매자는 탈퇴 후에도 사진, 영상, 글 등 브랜드 페이지에 포함된 모든 정보 및 자료가 계속하여 게재되는 것에
          동의합니다.<br/>
          <br/>
          제23 조 (캠페인서비스 운영에 대한 회사의 권리와 의무)<br/>
          회사의 의무<br/>
          ① 회사는 각 회 캠페인 시작일에 판매자의 상품에 대한 리뷰어 모집을 시작합니다.<br/>
          ② 회사는 각 회 캠페인 주문 접수일로부터 5영업일 내에 판매자가 상품 수취자의 배송정보를 확인하여 판매자에게 제공할 수 있도록 합니다.<br/>
          ③ 회사는 성실에 입각하여 '대상에 맞는 리뷰어'를 캠페인 리뷰어로 선정하기 위해 최선을 다하지만 그 내용을 보장하지 않습니다.<br/>
          ④ 회사는 택배형과 지역형 캠페인의 첫 리뷰 작성 완료 이후 판매자가 캠페인 결과리포트를 확인할 수 있도록 합니다.<br/>
          ⑤ 회사는 리뷰어의 리뷰율이 극대화될 수 있도록 운영하며, 제21조 제1항 ‘리뷰어의 의무’에 위반하는 리뷰에 대해서는 지속적으로 관리하여 정상 이행되도록 합니다.<br/>
          회사-판매자간 서비스 이용에 대한 협의<br/>
          ① 판매자는 캠페인 소재를 리뷰어 모집일 기준 7일 전까지 판매자센터에 업로드하여 검수를 완료하여야 합니다. 부득이 일정 내에 완료되지 않을 경우에는 판매자는 회사와의 사전 협의를 거쳐 진행일을
          조정할 수 있습니다.<br/>
          ② 리뷰어는 회원가입 시 등록한 내용을 바탕으로 선정하지만, 정보 미기재 또는 오기재의 사유로 선정된 리뷰어의 특성이 실제와 일부 차이가 발생할 수 있습니다.<br/>
          ③ 리뷰율은 단순 고객 변심, 제품 오배송 및 누락, 리뷰 작성 기간 지연 등의 사유로 전체 리뷰어 수의 80% 수준일 것으로 예상하며, 판매자는 리뷰율에 대하여 회사에 어떠한 책임도 물을 수
          없습니다.<br/>
          <br/>
          제24 조 (상품의 배송)<br/>
          판매자는 리뷰어에게 정해진 일자에 상품을 무상으로 배송하며, 리뷰어는 상품의 배송 지연 및 사고에 의한 미수취에 대해서 회사에 손해배상 청구를 할 수 없습니다.<br/>
          회사는 판매자의 상품 배송이 시작된 경우, 리뷰어에게 리뷰 작성을 요청할 수 있습니다.<br/>
          배송 사고가 발생한 경우, 회사는 판매자와 협의하여 상품 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
          배송과 관련하여 배송업체, 판매자, 회원(리뷰어) 등과의 사이에 분쟁이 발생한 경우에는 관련 당사자간에 해결하여야 합니다. 회사는 이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지
          않습니다.<br/>
          회사는 회원과 별도의 약정이 없는 이상, 리뷰어 선정일의 다음 영업일로부터 5일 이내에 상품을 배송할 수 있도록 판매자에 대한 주문, 포장 의뢰 등 기타의 필요한 조치를 취합니다<br/>
          판매자는 상품에 대한 배송수단, 배송비용 부담자, 배송기간 등을 명시합니다.<br/>
          판매자는 국내 및 국외 배송을 운영합니다. 단, 해외 배송 시 회사에서 제휴한 해외배송업체를 통해 배송대행을 요청할 수 있습니다.<br/>
          <br/>
          제25 조 (청약철회 등)<br/>
          리뷰어로 선정된 회원은 리뷰어로 선정된 이후에는 캠페인 서비스 신청 또는 상품 구매(즉시구매의 경우)를 취소하거나 리뷰어 자격을 포기할 수 없습니다.<br/>
          <br/>
          <br/>
          <br/>
          제3장 구매 서비스 (판매자로부터의 구매)<br/>
          <br/>
          제26 조 (제3장의 적용범위)<br/>
          본 장은 회원이 판매자로부터 직접 상품을 구매하는 경우에 적용합니다.<br/>
          <br/>
          제27 조 (서비스 이용원칙)<br/>
          회사는 통신판매중개자로서 회원과 판매자 간의 자유로운 상품의 거래를 위한 시스템을 운영, 관리, 제공함에 그치며, 서비스를 통하여 이루어지는 판매자와 회원 간의 거래에 일체 관여하지 아니하고 이
          거래에 관하여 판매자 또는 회원을 대리하지 않습니다.<br/>
          회사는 판매자가 제공하는 상품이 회원의 개별적 특성(신체적 특성 포함)에 적합하다거나 회원의 개별적 이용목적에 부합할 것이라는 점, 회원의 구매의사 또는 판매자의 판매의사의 존부 및 진정성, 상품의
          품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 판매자가 입력하거나 링크시킨 URL에 게시된 자료의 진실성 또는 적법성 등 일체에 대하여 어떠한 보증도 하지 않습니다.<br/>
          판매자가 직접 회사에 제공한 정보와 관련하여 회원이 입을 수 있는 손해 일체, 회원이 당해 상품을 사용함으로 인하여 입을 수 있는 손해 일체에 대한 책임은 당해 상품을 제공한 판매자에게 있으며,
          이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.<br/>
          <br/>
          제28 조 (구매 신청 및 계약 성립)<br/>
          회원은 서비스상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 판매자는 회원의 구매신청을 위하여 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의
          적용을 제외할 수 있습니다.<br/>
          ① 상품의 검색 및 선택<br/>
          ② 성명, 주소, 전화번호, 전자우편주소, 핸드폰번호 등의 입력<br/>
          ③ 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의비용부담과 관련한 내용에 대한 확인<br/>
          ④ 본 약관에 동의하고 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스클릭)<br/>
          ⑤ 상품의 구매신청 및 이에 관한 확인<br/>
          ⑥ 결제방법의 선택<br/>
          회원은 상품을 구매하기 전에 반드시 사이트 내에 작성한 거래의 조건을 정확하게 확인하여야 합니다. 거래의 조건을 확인하지 않고 상품을 구매하여 발생한 모든 손실, 손해는 회원 본인에게
          있습니다.<br/>
          회원은 상품 구매 시 타인의 결제 수단을 임의/무단으로 사용하여서는 안됩니다. 타인의 결제 수단을 임의/무단으로 사용함으로써 발생하는 회사 및 결제 수단 소유자의 손실과 손해에 대한 모든 책임은
          회원에게 있습니다.<br/>
          서비스 이용대금의 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담하여야 합니다.<br/>
          판매자는 다음 각 호의 경우 회원의 구매신청을 승낙하지 않을 수 있습니다.<br/>
          ① 구매신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
          ② 미성년자가 담배, 주류 등 청소년 보호법등 관련법령에서 금지하는 상품을 구매하는 경우<br/>
          ③ 기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우<br/>
          판매자는 회원의 구매신청이 있는 경우 회원에게 수신확인통지(구매신청이 완료되었다는 팝업창, SMS, 이메일 등)를 합니다. 이 경우 회사의 수신확인의 의사표시에는 회원의 구매신청에 대한 확인 및
          판매가능여부, 구매신청의 정정 또는 취소 등에 관한 정보를 포함하여야 합니다.<br/>
          전항의 수신확인 통지가 회원에게 도달한 시점에 구매계약이 성립한 것으로 봅니다.<br/>
          판매자는 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
          <br/>
          제29 조 (상품의 배송)<br/>
          회원은 판매자의 상품 배송 지연 및 사고에 의한 미수취에 대해서 회사에 손해배상 청구를 할 수 없습니다.<br/>
          회사는 판매자와 협의하여 상품 배송 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
          배송과 관련하여 배송업체, 금융기관 등과의 사이에 분쟁이 발생한 경우에는 관련 당사자간에 해결하여야 합니다. 회사는 이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지 않습니다.<br/>
          판매자는 회원과 별도의 약정이 없는 이상, 회원의 구매신청이 있는 날의 다음 영업일로부터 7일 이내에 상품을 배송할 수 있도록 주문, 포장 의뢰 등 기타 필요한 조치를 취합니다. 다만, 회원이 이미
          상품 대금의 전부 또는 일부를 지급한 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 필요한 조치를 취합니다.<br/>
          판매자는 상품에 대한 배송수단, 배송비용 부담자, 배송기간 등을 명시합니다.<br/>
          판매자는 국내 및 국외 배송을 운영합니다. 단, 해외배송시 회사에서 제휴한 해외배송업체를 통해 배송대행을 요청할 수 있습니다.<br/>
          <br/>
          제30 조 (품절 등을 이유로 한 환불)<br/>
          판매자는 회원이 구매 신청한 상품이 품절 등의 사유로 제공될 수 없을 때지체 없이 그 사유를 회원에게 통지하고, 사전에 상품의 대금을 받은 경우에는 회원이 구매 신청한 상품이 품절 등의 사유로
          제공될 수 없음이 확인된 날로부터 3영업일 이내에 환불하거나 환불에 필요한 조치를 취합니다.<br/>
          <br/>
          제31 조 (청약철회 등)<br/>
          회원은 상품을 배송받은 날로부터 7일 이내까지 청약철회(구매취소, 반품, 교환을 포함합니다. 이하 ‘청약철회 등’)를 할 수 있습니다.<br/>
          회원은 상품을 배송받은 경우 다음 각 호에 해당하는 경우에는 청약철회 등을 할 수 없습니다.<br/>
          ① 회원에게 책임 있는 사유로 상품 또는 택(tag)이 멸실 또는 훼손된 경우(다만, 상품의 내용 확인을 위하여 포장을 뜯은 경우는 제외)<br/>
          ② 회원의 사용 또는 일부 소비로상품의 가치가 현저히 감소한 경우<br/>
          ③ 시간의 경과로 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우<br/>
          ④ 같은 성능을 지닌 상품으로 복제가 가능한 경우 그 원본인 상품의 포장을 훼손한 경우<br/>
          ⑤ 그 밖에 거래의 안전을 위하여 「전자상거래 등에서의 소비자보호에 관한 법률」 시행령 제21조(청약철회 등의 제한)에서 정하는 경우<br/>
          제2항 제2호 내지 제4호의 경우, 판매자는 사전에 청약철회 등이 제한된다는 사실을 상품의 포장이나 그 밖에 회원이 쉽게 알 수 있는 곳에 명확하게 표시하거나 시험 사용품을 제공하는 등의 방법으로
          회원의 청약철회 등 권리행사가 방해되지 않도록 조치하여야 합니다.<br/>
          제1항 및 제2항에도 불구하고, 상품의 내용이 표시, 광고의 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 상품을 공급받은 날부터 3월 이내, 그리고 그 사실을 안 날 또는 알 수 있었던
          날부터 30일 이내에 청약철회 등을 할 수 있습니다.<br/>
          회원은 이용한 서비스에 청약철회의 원인이 발생한 경우 수령한 상품을 임의로 사용하거나 훼손되도록 방치하여서는 안됩니다. 청약철회한 상품의 임의 사용이나 상품 보관의 미흡으로 인한 상품훼손에 대하여는
          회원이 합당한 비용을 부담하여야 합니다.<br/>
          <br/>
          제32 조 (청약철회 등의 효과)<br/>
          회원이 전조에 따라 청약철회 등을 한 경우, 회원은 이미 공급받은 상품을 지체 없이 판매자에게 반환하여야 하며, 회사는 판매자가 회원으로부터 상품을 반환받았음을 회사에 통지한날로부터 3영업일 이내에
          회원에게 상품의 대금을 환불합니다. 만약 회원이 상품 배송이 시작되기 이전에 전조에 따른 청약철회 등을 한 경우,회사는 판매자가 회원의 청약철회 등의 의사표시가 판매자에게 도달하였음을 회사에 통지한
          날로부터 3영업일 이내에 회원으로부터 이미 지급받은 상품의 대금을 환불합니다.<br/>
          전항의 경우 회사가 회원에게 환불을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률」 시행령이 정하는 지연배상금 이율(연 100분의 15)을 곱하여 산정한
          지연배상금을 지급합니다.<br/>
          회사는 전항에 따른 환불 시에 회원이 신용카드 또는 전자화폐 등의 결제수단으로 상품의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자(이하 ‘결제업자’)로 하여금 상품대금의 청구를
          정지 또는 취소하도록 요청합니다. 다만, 회사가 결제업자로부터 해당 상품의 대금을 이미 받은 때에는 지체 없이 그 대금을 결제업자에게 환급하고, 그 사실을 회원에게 알려야 합니다.<br/>
          청약철회등의 경우 공급받은 상품의 반환에 필요한 비용은 회원이 부담합니다. 다만 상품의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 상품의 반환에 필요한
          비용은 회사가 부담합니다.<br/>
          회사 또는 판매자는 회원에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다.<br/>
          회원이 상품을 제공받을 때 배송비를 부담한 경우, 판매자는 회원의 청약철회 등이 있을 때 그 비용을 누가 부담하는지를 회원이 알기 쉽도록 명확하게 표시합니다.<br/>
          이미 상품이 일부 사용되거나 일부 소비된 경우, 판매자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제18조 제8항에 따라 회원에게 일정한 비용을 청구할 수 있습니다.<br/>
          상품을 반환받은 판매자는 배송비 정산, 상품 확인이 필요할 경우 환불을 보류할 수 있고, 전조 제2항에 따른 청약철회 제한 사유에 해당하는 등 정당한 사유가 있는 경우에는 환불을 거부할 수
          있습니다.<br/>
          판매자는 회원이 반환한 상품을 수령해야 하며, 판매자의 연락 두절 등의 사유로 상품을 반환할 수 없어 회원이 직접 회사로 상품을 반환할 때에는 회사는 상당 기간을 정하여 해당 상품을 수령할 것을
          판매자에게 통지하고, 판매자가 해당 기간 내에 특별한 의사를 표시하지 않으면 회사는 해당 상품을 폐기할 수 있습니다.<br/>
          상품에 하자 또는 사용상의 안전성에 결함이 있는 경우, 판매자는 전량 리콜(수리, 교환, 환불)하여야 하며, 리콜에 따른 모든 비용을 부담하여야 합니다.<br/>
          <br/>
          <br/>
          제4장 리터 서비스<br/>
          <br/>
          제33 조 (제4장의 적용범위)<br/>
          본 서비스는 본 이용 약관 (이하 “본 약관”) 및 당사의 서비스 약관에 따릅니다. 본 약관과 당사의 서비스 약관이 상충하는 경우, 본 약관이 우선합니다.<br/>
          <br/>
          리터(LITER) (서비스 이용원칙)<br/>
          공팔리터를 사용하면서 구매 및 리뷰작성, 타 리뷰어로부터의 후원, 추천하기 서비스 등을 통해 리터로 보상을 받을 수 있으며, 구매를 통해서도 리터를 적립할 수 있습니다. (이하 통칭하여 “리터”).<br/>
          리터는 그 어떤 법적 통화가 아닌 공팔리터 내에서의 리워드 시스템으로, 귀하의 총 잔액은 마이 페이지에 표시됩니다.<br/>
          추천 가능 상품에 한하여 작성한 리뷰를 통해 지인에게 추천하고 그 지인이 해당 상품을 구매하면 구매액의 일정 비율을 등급별 적립율에 따라 리터로 돌려받을 수 있습니다. 마일리지 적립률 및 기준은
          내부 방침에 따라 임의로 변경될 수 있습니다.<br/>
          리터 획득 방법 공팔리터 내에서 리터를 획득하는 방법에는 다음이 포함됩니다:<br/>
          ① 리뷰 작성에 따른 보상<br/>
          ② 본인의 판매링크를 통한 다른 리뷰어들의 구매에 따른 보상<br/>
          ③ 다른 리뷰어들의 후원<br/>
          ④ 리터의 구매<br/>
          회원은 공팔리터 서비스를 통해 상품/서비스를 경험한 후 리뷰 작성에 따른 보상으로 리터를 획득할 수 있습니다.<br/>
          회원은 본인의 리뷰에 삽입된 링크를 통해 다른 사용자가 링크에 연결된 해당 상품을 구매하는 경우 상품을 제공/판매하는 판매자사와 정해진 수수료율에 따라 리터로 보상을 받을 수 있습니다.<br/>
          판매 링크에 의한 보상의 크기는 회원의 등급, 판매자사와의 계약 조건에 따라 다를 수 있습니다.<br/>
          회원의 솔직하고 유용한 리뷰에 대해 다른 사용자들이 자신들이 보유하고 있는 리터를 후원의 형태로 리뷰어에게 지급함으로써 리터를 획득할 수 있습니다.<br/>
          회사가 리터를 판매하는 경우, 회원들은 카드결제 또는 현금입금 등 회사가 정하는 결제방법을 통해 리터를 직접 구매할 수 있습니다.<br/>
          이러한 방법들 외에 회사가 진행하는 각종 이벤트나 프로모션 등을 통해서 리터를 지급받음으로써 리터를 획득/적립할 수 있습니다.<br/>
          <br/>
          제34 조 (청약철회 등)<br/>
          회사의 권한<br/>
          당사는 본 서비스를 정당한 사유가 있는 경우 언제든지 정지시킬 수 있습니다. 당사는 악용, 사기, 또는 공팔리터의 서비스 약관, 개인정보 처리방침, 본 약관을 위반하는 것으로 판단되는 활동이 확인될
          경우 언제든지 사전 통지 없이 본 서비스를 중단할 수 있는 권리를 보유합니다. 당사는 당사의 단독 재량에 따라 당사가 공정하고 적절하다고 판단하는 대로 모든 활동을 검토 및 조사하고 적립 및 환전
          활동을 수정할 수 있는 권리를 보유합니다.<br/>
          당사는 귀하가 다음과 같은 사항에 해당한다는 합리적인 근거가 있을 경우 당사의 단독 재량에 따라 귀하에 대한 서비스를 중지하거나, 이용제한 또는 지급을 거부할 수 있는 권리를 보유합니다.<br/>
          ① 리워드 시스템의 진행 또는 운영에 개입하거나 개입하려고 시도했을 경우<br/>
          ② 공팔리터의 정책 또는 서비스 약관을 위반했을 경우<br/>
          ③ 타인에게 피해를 입히는 행동 또는 활동을 취했을 경우<br/>
          ④ 본 서비스와 관련하여 허위 진술 또는 악용을 하였거나 불법 또는 범죄 활동(사기 포함)을 한 경우<br/>
          본 서비스를 이용함으로써 귀하는 본 약관에 명시된 공식적인 규칙과 당사의 결정에 동의합니다. 당사는 어떠한 종류의 기술적, 하드웨어, 소프트웨어 또는 통신기기 고장에 대해서도 책임지지 않으며, 귀하
          또는 본 서비스와 관련되거나 본 서비스에 사용되는 어떠한 장비나 프로그래밍에 의해 야기된 네트워크 연결의 손실, 부재, 고장, 불완전함, 부정확함이나 지연, 또는 본 서비스의 진행 과정에서 발생할
          수 있는 인적 또는 비인적 오류에 대한 책임을 부담하지 않습니다. 또한 당사는 그 어떤 제3자에 대해서도 책임지지 않습니다.<br/>
          <br/>
          문의<br/>
          본 약관에 대한 문의사항은 공팔리터를 통해 해당 부서에 보내주시기 바랍니다.<br/>
          ① “마이페이지” 탭을 클릭합니다.<br/>
          ② 우측 상단의 버튼을 클릭하여 “설정 및 개인정보”를 엽니다.<br/>
          ③ “1:1 문의”를 클릭합니다.<br/>
          <br/>
          <br/>
          제5장 리터 충전, 인출 서비스<br/>
          <br/>
          제35 조 (리터 충전 서비스)<br/>
          리터 충전은 신용카드, 휴대폰 결제 등 회사에서 제공하는 결제방식을 통해서 가능합니다. 단, 각 결제수단을 운영하는 사업자가 별도로 있는 경우, 회원은 해당 결제수단을 이용하기 전에 해당 결제수단의
          사업자가 제시하는 절차를 이행하여야 합니다. 회사의 서비스 이용요금을 결제할 때에는 해당 결제수단의 사업자가 제시하는 절차 이행 및 이용약관에 동의한 것으로 간주합니다.<br/>
          리터 충전은 회사의 내부 정책에 의거하여 제공되는 단위로 충전할 수 있으며, 회사는 정책에 따라 월 충전 한도를 제한할 수 있으며, 이 때 결제 수단 운영회사나 정부의 방침 등에 따라 각
          결제수단별로 별도의 결제한도가 부여될 수 있습니다.<br/>
          회사는 다음의 아래에 해당하는 충전신청에 대해서는 승인을 하지 않거나, 추후 해당 승인을 취소할 수 있습니다.<br/>
          ① 실명이 아닌 경우<br/>
          ② 타인의 명의를 이용 또는 도용하여 신청하는 경우<br/>
          ③ 필수적 기재사항을 누락 또는 오기했거나 필수적 절차가 미비한 경우<br/>
          ④ 이용 요금을 납부하지 않거나 납부자를 확인할 수 없는 경우<br/>
          ⑤ 결제수단 명의자의 동의나 승낙 없이 결제 수단을 무단 도용하여 이용요금을 결제한 경우<br/>
          ⑥ 그 밖에 회원의 귀책사유로 승인이 불가능하다고 판단되는 경우<br/>
          회사는 다음의 아래에 해당하는 경우에는 이용신청에 대한 승인 제한 사유가 해소될 때까지 승인을 제한할 수 있습니다.<br/>
          ① 서비스 설비가 부족하여 만족스러운 서비스를 제공할 수 없다고 판단될 경우.<br/>
          ② 서비스 상의 장애가 발생한 경우<br/>
          리터는 회사에서 정한 제 4장에 안내 된 범위에서 사용 가능하며, 회원의 결제 및 이용 내역 등은 홈페이지에서 확인하실 수 있습니다.<br/>
          리터 잔액에 대한 이자수익은 발생하지 않습니다.<br/>
          리터는 유효기간은 지급일로 부터 1년이며, 이후 소멸처리 됩니다.<br/>
          유료로 충전된 리터는 지급 일로부터 5년간 환불이 가능합니다.<br/>
          문의<br/>
          본 약관에 대한 문의사항은 공팔리터를 통해 해당 부서에 보내주시기 바랍니다.<br/>
          ① “마이페이지” 탭을 클릭합니다.<br/>
          ② 우측 상단의 버튼을 클릭하여 “설정 및 개인정보”를 엽니다.<br/>
          ③ “1:1 문의”를 클릭합니다.<br/>
          <br/>
          제36 조 (리터 환불)<br/>
          청약철회<br/>
          회사와 리터 구매에 관한 계약을 체결한 회원은 리터 충전일로 부터 7일 이내 청약철회를 할 수 있습니다.<br/>
          회원은 회사의 1:1 문의를 통하여 청약철회를 신청할 수 있습니다. 회원이 청약철회를 신청한 경우 회사는 지체없이 회원의 리터를 회수 또는 삭제하고 리터를 회수한 날로부터 3영업일 이내 지급받은
          대금을 환급합니다.<br/>
          회원은 회사의 1:1 문의를 통해서 청약 철회를 신청할 수 있습니다.<br/>
          리터의 환불<br/>
          회원은 리터 청약철회가 불가능한 경우에도 제 35조 8항에 따라 소멸하지 않은 리터 잔액에 대한 환불을 요청할 수 있습니다.<br/>
          회원이 자신의 ID를 통해 충전한 리터 잔액에 대한 환불을 요청하면 적법한 절차에 따라 환불을 받으실 수 있습니다. 단, 회원이 무료로 지급 받은 리터는 환불 받을 수 없습니다.<br/>
          환불은 결제 된 리터 상품의 60% 이상 사용된 경우 나머지 잔액에 대하여 전액 환불이 가능합니다. 단, 회사의 귀책사유가 있는 경우에는 사용여부와 상관없이 환불처리 됩니다.<br/>
          주민등록법, 저작권법, 컴퓨터프로그램보호법, 정보통신망법 등 각종 법령에 대한 중대한 불법행위 또는 서비스 내 기타 위법 또는 도박, 영리추구, 미풍양속 저해 등 부정한 이용 행위를 한 것으로
          판단되는 이용자의 계정 및 아이디 이용을 제한하거나 해당 이용자의 계약을 해지하는 경우, 회사는 리터 환불을 하지 않을 수 있습니다. 다만, 이용자가 위 내용에 대하여 소명하는 경우에는 그러하지
          않습니다.<br/>
          문의<br/>
          본 약관에 대한 문의사항은 공팔리터를 통해 해당 부서에 보내주시기 바랍니다.<br/>
          ① “마이페이지” 탭을 클릭합니다.<br/>
          ② 우측 상단의 버튼을 클릭하여 “설정 및 개인정보”를 엽니다.<br/>
          ③ “1:1 문의”를 클릭합니다.<br/>
          <br/>
          제37 조 (리터 인출)<br/>
          당사와의 관계<br/>
          본 서비스는 본 이용 약관 (이하 “본 약관”) 및 당사의 서비스 약관에 따릅니다. 본 약관과 당사의 서비스 약관이 상충하는 경우, 본 약관이 우선합니다.<br/>
          본 인출 서비스(“본 서비스”)의 제공자는 (주)공팔리터(“당사”)이며, 당사는 대한민국에 등록된 회사입니다.<br/>
          본 약관에 명시된 리터(LITER)를 현금으로 지급하기 위한 사전조건으로 당사는 언제든지 귀하의 자격 여부를 증명하는 데 필요한 추가적인 개인 정보를 요청하기 위해 귀하에게 연락을 취할 수
          있습니다.<br/>
          서비스 이용 자격<br/>
          본 서비스를 이용하려면 당사의 플랫폼을 통하여 적립 또는 구매한 리터의 총 합계액이 당사가 정한 기준을 초과해야 합니다.<br/>
          리터의 현금 인출 기준은 사전에 공지되며, 기준 변경 시에도 사전에 회원들에게 공지됩니다.<br/>
          본 서비스를 이용함으로써 귀하는 본 서비스의 필수적인 부분으로 간주되는 다음 약관들을 모든 목적을 위해서 인정하고 수락한 것으로 간주됩니다.<br/>
          ① 인출 서비스 이용안내<br/>
          ② 공팔리터 개인정보 처리방침<br/>
          ③ 서비스 이용약관<br/>
          ④ 아래에 명시된 본 서비스의 모든 지침과 조항 및 본 서비스에 참여하기 위해 요구될 수. 있는 기타 약관<br/>
          귀하가 항목 명시된 약관들에 동의하지 않거나 본 서비스의 자격 및 조건을 준수하지 않는 경우, 어떤 경우에도 본 서비스를 이용할 수 없으며 자동으로 자격이 박탈될 수 있습니다.<br/>
          당사는 언제든지 리터 금액 및 지급 방법을 포함한 약관의 내용을 변경할 수 있습니다.<br/>
          리터 인출 서비스 이용자격 조건<br/>
          본 서비스를 이용하기 위해서 귀하는 다음의 조건을 충족해야 합니다.<br/>
          ① 당사의 어플리케이션 0.8L(이하 “공팔리터”)에 본인 계정을 보유하고 있어야 합니다.<br/>
          ② 현재 대한민국의 법적 거주자이고, 만 19세 이상이며, 서비스 약관 및 개인정보 처리방침에. 따라 공팔리터에 정기적으로 등록되어 있어야 합니다.<br/>
          ③ 출금을 위해 공팔리터에 등록하는 계좌는 유효해야 하며, 회원 본인 명의여야 합니다. 출금 약관(본 약관의 항목 2의 명시된 약관)은 리터 인출 화면 내에 제공되는 링크를 통해 확인할 수
          있습니다.<br/>
          리터 인출 서비스 내 리터의 구분<br/>
          리터는 획득 방법에 따라 무료 리터, 유료 리터를 구분합니다.<br/>
          [무료 리터]<br/>
          ① 리뷰 작성에 따른 보상<br/>
          ② 본인의 판매링크를 통한 다른 리뷰어들의 구매에 따른 보상<br/>
          ③ 다른 리뷰어들의 후원<br/>
          ④ 공팔리터에서 진행하는 각종 이벤트나 프로모션 보상<br/>
          공팔리터를 사용하면서 구매 및 리뷰작성, 타 리뷰어로부터의 후원 등을 통해 리터로 보상을 받을 수 있으며, 구매를 통해서도 리터를 적립할 수 있습니다. (이하 통칭하여 “리터”). 적립한 리터는 본
          약관에서 정하는 방법과 비율로 현금으로 전환할 수 있습니다.<br/>
          [유료 리터]<br/>
          ① 리터의 구매<br/>
          리터는 그 어떤 법적 통화가 아닌 공팔리터 내에서의 리워드 시스템으로, 귀하의 총 잔액은 마이 페이지에 표시됩니다.<br/>
          귀하는 잔액이 일정 기준 이상이 되는대로 “출금” 버튼을 클릭해서 출금 기준에 따라 잔액을 출금할 수 있습니다. 해당 금액은 회원의 등록 계좌로 현금으로 직접 지급됩니다.<br/>
          리터 인출 시에는 대한민국 조세법을 기반으로 소득세 및 개인 원천세 등이 발생합니다. (액수에 관계없이 소득세 3%, 주민세 0.3%) 수수료는 회사의 내부 방침에 따라 변경될 수
          있습니다.<br/>
          리터를 현금을 인출 받으면 인출 액수에 관계없이 종합소득세 신고의무가 발생합니다.<br/>
          리터의 출금에는 본 약관, 출금 약관, 출금 시점에 당사가 제공한 모든 추가 정보 등 모든 약관이 적용되며, 이는 일일 출금 한도액을 포함할 수 있습니다. 당사는 결제 수단을 포함한 제3자 서비스의
          이용 불가함에 대해 책임을 지지 않습니다.<br/>
          당사의 목표는 모든 리터의 출금 요청에 적시에 대응하는 것이지만, 당사는 (출금 시점에 설정된 예상 완료일을 포함한) 특정 기간 내에 요청이 완료될 것을 보장하지는 않으며, 당사는 귀하 또는
          제3자에게 당사가 특정 기간 내에 출금 요청에 따르지 못하는 경우에 대한 책임을 지지 않습니다.<br/>
          유료 리터의 인출<br/>
          본 장 ‘4’에서 정한 유료 리터의 경우 리터 인출을 위해서 결제완료일로 부터 1개월 이후 인출이 가능하도록 하며, 이는 인출을 통한 결제 현금화를 방지하기 위한 조치 입니다.<br/>
          결제 후 1개월 이내 인출을 원하는 경우 고객센터를 통해 회사가 정한 정책대로 결제 취소 후 환불 조치를 받으실 수 있습니다.<br/>
          리터 획득 방법과 인출 기준<br/>
          공팔리터 내에서 리터를 획득하는 방법에는 다음이 포함됩니다:<br/>
          - 리뷰 작성에 따른 보상<br/>
          - 본인의 판매링크를 통한 다른 리뷰어들의 구매에 따른 보상<br/>
          - 다른 리뷰어들의 후원<br/>
          - 공팔리터에서 진행하는 각종 이벤트나 프로모션 보상<br/>
          - 리터의 구매<br/>
          회원은 공팔리터 서비스를 통해 상품/서비스를 경험한 후 리뷰 작성에 따른 보상으로 리터를 획득할 수 있습니다.<br/>
          회원은 본인의 리뷰에 삽입된 링크를 통해 다른 사용자가 링크에 연결된 해당 상품을 구매하는 경우 상품을 제공/판매하는 파트너사와 정해진 수수료율에 따라 리터로 보상을 받을 수 있습니다.<br/>
          판매 링크에 의한 보상의 크기는 회원의 등급, 파트너사와의 계약 조건에 따라 다를 수 있습니다.<br/>
          회원의 솔직하고 유용한 리뷰에 대해 다른 사용자들이 자신들이 보유하고 있는 리터를 후원의 형태로 리뷰어에게 지급함으로써 리터를 획득할 수 있습니다.<br/>
          회사가 리터를 판매하는 경우, 회원들은 카드결제 또는 현금입금 등 회사가 정하는 결제방법을 통해 리터를 직접 구매할 수 있습니다.<br/>
          이러한 방법들 외에 회사가 진행하는 각종 이벤트나 프로모션 등을 통해서 리터를 지급받음으로써 리터를 획득/적립할 수 있습니다.<br/>
          최소 출금액은 100,000리터이며, 1리터는 1원의 가치로 환산되어 지급됩니다. 리터의 인출 및 인출 시 ‘4’의 내용과 같이 소득세와 주민세 및 수수료가 차감된 후 지급됩니다.<br/>
          출금 신청한 금액은 등록된 본인 확인 서류가 일치하는 경우 최대 2영업일 이내 지급되며, 단, 입금예정일이 휴일인 경우엔 다음 영업일에 지급됩니다.<br/>
          리터 인출 수수료는 회사에서 내부 방침에 따라 임의 변경될 수 있습니다.<br/>
          계정 또는 출금 계좌의 소유자가 공팔리터의 이용약관이나 운영원칙을 위반하는 경우, 법률을 위반하거나, 조사기관으로부터 조사 중인 경우, 법적인 문제로 법원, 검찰, 경찰, 세무 당국으로부터의
          지급중지 요청이 있는 경우엔 지급이 지연되거나 중지될 수 있습니다.<br/>
          회사의 권한과 일반 규칙<br/>
          당사는 본 서비스를 정당한 사유가 있는 경우 언제든지 정지시킬 수 있습니다. 당사는 악용, 사기, 또는 공팔리터의 서비스 약관, 개인정보 처리방침, 본 약관을 위반하는 것으로 판단되는 활동이 확인될
          경우 언제든지 사전 통지 없이 본 서비스를 중단할 수 있는 권리를 보유합니다. 당사는 당사의 단독 재량에 따라 당사가 공정하고 적절하다고 판단하는 대로 모든 활동을 검토 및 조사하고 적립 및 인출
          활동을 수정할 수 있는 권리를 보유합니다.<br/>
          회사는 사용자가 부정, 위법한 목적, 약관 기타 서비스 취지에 반하는 방법으로 이용하거나 인출요청을 한 경우에는 결제 승인을 취소하거나 그 인출 등을 거절, 정지할 수 있으며, 해당 서비스를 반환,
          회수할 수 있습니다.<br/>
          인출의 제한은 정상적인 이용이라는 점이 밝혀지거나 결제 대금이 정상 납부될 경우에는 해제될 수 있습니다.<br/>
          당사는 귀하가 다음과 같은 사항에 해당한다는 합리적인 근거가 있을 경우 당사의 단독 재량에 따라 귀하에 대한 서비스를 중지하거나, 이용제한 또는 지급을 거부할 수 있는 권리를 보유합니다.<br/>
          1) 리워드 시스템의 진행 또는 운영에 개입하거나 개입하려고 시도했을 경우<br/>
          2) 공팔리터의 정책 또는 서비스 약관을 위반했을 경우<br/>
          3) 타인에게 피해를 입히는 행동 또는 활동을 취했을 경우<br/>
          4) 본 서비스와 관련하여 허위 진술 또는 악용을 하였거나 불법 또는 범죄 활동(사기 포함)을 한 경우<br/>
          본 서비스를 이용함으로써 귀하는 본 약관에 명시된 공식적인 규칙과 당사의 결정에 동의합니다. 당사는 어떠한 종류의 기술적, 하드웨어, 소프트웨어 또는 통신기기 고장에 대해서도 책임지지 않으며, 귀하
          또는 본 서비스와 관련되거나 본 서비스에 사용되는 어떠한 장비나 프로그래밍에 의해 야기된 네트워크 연결의 손실, 부재, 고장, 불완전함, 부정확함이나 지연, 또는 본 서비스의 진행 과정에서 발생할
          수 있는 인적 또는 비인적 오류에 대한 책임을 부담하지 않습니다. 또한 당사는 그 어떤 제3자에 대해서도 책임지지 않습니다.<br/>
          문의<br/>
          본 약관에 대한 문의사항은 공팔리터를 통해 해당 부서에 보내주시기 바랍니다.<br/>
          ① “마이페이지” 탭을 클릭합니다.<br/>
          ② 우측 상단의 버튼을 클릭하여 “설정 및 개인정보”를 엽니다.<br/>
          ③ “1:1 문의”를 클릭합니다.<br/>
          <br/>
          <br/>
          제6장 커뮤니티 서비스<br/>
          제38 조 (채팅 서비스)<br/>
          회사는 회원간 커뮤니티 서비스를 위해서 회원간 채팅 서비스 지원합니다.<br/>
          회원은 채팅 서비스 이용 중 4항에서 설명 된 서비스 목적에 맞지 않게 사용 중인 회원의 채팅을 신고할수 있으며, 신고를 통한 결과 내역은 회사의 담당자에게 전달 되며, 회사의 담당자는 신고 내용을
          검토 후 내부 정책의 의거하여, 회원의 서비스 이용 제한 처리 할 수 있습니다. (단, 이용제한 처리가 부당한 경우 고객센터를 통하여 소명하여야 합니다.)<br/>
          회원은 회사의 채팅 서비스 제공 목적에 맞지 않는 경우를 확인해야 할 의무가 있습니다.<br/>
          ① 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용<br/>
          ② 특정 국적, 인종, 종교, 민족, 성별, 장애 등을 일방적으로 조롱하거나 해를 끼치는 내용인 경우<br/>
          ③ 회원이 음란물을 게재하거나 음란사이트를 링크한 경우<br/>
          ④ 회사 또는 제3자의 저작권 등 기타 지적재산권을 포함한 일체의 권리를 침해하는 내용인 경우<br/>
          ⑤ 게시판의 성격에 부합하지 않는 채팅의 경우<br/>
          ⑥ 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우<br/>
          ⑦ 영리를 목적으로 하는 광고성 내용인 경우<br/>
          ⑧ 범죄와 결부된다고 객관적으로 인정되는 내용일 경우<br/>
          ⑨ 회사에서 규정한 채팅 원칙에 어긋나거나 서비스 성격에 부합하지 않는 경우<br/>
          ⑩ 회사나 서비스의 정상적인 운영을 방해하는 경우<br/>
          ⑪ 기타 관계법령에 위배된다고 판단되는 경우<br/>
          ⑫ 사회적인 이슈로 상품에 문제가 생겼다 판단되는 경우<br/>
          ⑬ 상품 제공자가 회원의 채팅으로 인해 재산상의 손해를 입었다 판단되어 요청하는 경우<br/>
          문의<br/>
          본 약관에 대한 문의사항은 공팔리터를 통해 해당 부서에 보내주시기 바랍니다.<br/>
          ① “마이페이지” 탭을 클릭합니다.<br/>
          ② 우측 상단의 버튼을 클릭하여 “설정 및 개인정보”를 엽니다.<br/>
          ③ “1:1 문의”를 클릭합니다.<br/>
          <br/>
          <br/>
          제7장 기타 사항<br/>
          제39 조 (저작권의 귀속 및 이용제한)<br/>
          회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.<br/>
          회사의 캠페인 서비스를 이용함으로써 제공받은 상품에 대해 회원이 저작물을 작성하게 된 경우 그에 대한 저작권 및 기타 지적재산권은 회사에 귀속합니다.<br/>
          회사의 플랫폼 내 타인에게 보여지는 모든 정보에 대하여 ‘‘제 1장, 제 10조, 7항과 제 16조, 2항”을 적용하며, 회원은 각 호에 해당하는 행위를 해서는 안됩니다. 위반 시 서비스 이용제한
          될 수 있으며, 회사에 손해가 발생한 경우, 회원은 회사에 대해 손해배상책임을 부담하게 됩니다. (손해배상금액의 범위는 해당 상품의 정상가와 배송비, 손해배상액을 청구 및 수취하는 과정에서 발생하는
          모든 비용의 합계액 이내로 합니다. )<br/>
          회사는 서비스 이용 약관의 의거하여, 위반한 회원에 대하여 이용제한 처리 진행 할 수 있으며, 서비스 이용 약관은 시행일 7일 이전 회원에게 고지할 의무를 가집니다.<br/>
          <br/>
          제40 조 (분쟁해결)<br/>
          회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상 처리기구를 설치•운영합니다.<br/>
          회사는 회원으로부터 제출되는 불만사항 및 의견을 신속하게 처리하도록 노력합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보합니다.<br/>
          회사와 회원간에 발생한 전자상거래 분쟁과 관련하여 회원의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시•도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.<br/>
          회사는 서비스 내에서 회원들 간에 발생하는 분쟁 및 회원 스스로의 과실로 일어난 피해에 대해 관여하거나 이에 대하여 일체의 책임을 부담하지 않습니다. 다만 서비스 이용 중 다른 회원의 본 약관 및
          관련법령 등 위반 행위에 의한 피해를 입으신 경우, 회원은 이를 회사에 제보할 수 있으며 그 경우 회사는 신고내용을 확인하고 본 약관 등에 따른 조치를 취할 수 있습니다.<br/>
          회원은 온라인문의, 전화상담을 통해 회사에서 제공하는 서비스에 대한 관련한 각종 문의를 포함하여 요청 및 건의를 할 수 있습니다.<br/>
          [온라인 문의] 문의가능시간: 24시간<br/>
          [전화 문의] 월요일 ~ 금요일 (9:30 ~ 17:30), 토/일/공휴일 휴무<br/>
          <br/>
          제41 조 (면책)<br/>
          전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 회사의 책임이 면제됩니다.<br/>
          회사는 회원의 귀책사유로 인한 서비스의 중지/이용장애에 대하여 책임을 부담하지 않습니다.<br/>
          기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 회사의 책임이 면제됩니다.<br/>
          사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우는 회사의 책임이 면제됩니다.<br/>
          회사는 회원이 서비스 내 또는 웹사이트상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대해서는 책임을 부담하지 않습니다.<br/>
          회사는 회원 상호간 또는 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 부담하지 않습니다.<br/>
          <br/>
          제42 조 (재판권 및 준거법)<br/>
          회사와 회원간에 발생한 전자거래 분쟁에 관한 소송은 제소 당시의 회사의 주소지 또는 제소 당시의 회원의 주소 또는 거소를 관할하는 법원에 제기할 수 있습니다. 회원의 주소 또는 거소가 분명하지
          않거나 외국 거주자의 경우에는 「민사소송법」에서 정하는 관할 법원에 제기할 수 있습니다.<br/>
          회사와 회원, 판매자와 회원 사이에 제기된, 본 약관에 따른 거래와 관련된 소송에는 대한민국 법을 적용합니다.<br/>
          <br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2016년 7월 1일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2016년 12월 1일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2017년 2월 6일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2017년 3월 24일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2017년 7월 25일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2017년 8월 17일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2018년 7월 13일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2018년 10월 10일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2019년 1월 30일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2020년 4월 14일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2020년 6월 15일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2020년 9월 1일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2020년 10월 19일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2021년 3월 10일부터 시행합니다.<br/>
          부 칙<br/>
          제1조 (시행일) 본 약관은 2021년 9월 6일부터 시행합니다.<br/>
        </div>
      </div>
      <div class="d-flex justify-center mt-4 mb-2 pb-4 pl-4 pr-4">
        <v-btn @click="sheet = false"
               color="secondary"
               class="text-body-1 font-weight-bold pa-6"
               block rounded>확인
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'TermsOfServiceBottomSheet',
  created () {
    this.$parent.$on('showTermsOfServiceBottomSheet', this.showTermsOfServiceBottomSheet)
  },
  data: () => ({
    sheet: false
  }),
  methods: {
    showTermsOfServiceBottomSheet () {
      this.sheet = true
    }
  }
}
</script>

<style scoped>

</style>
