<template>
  <div class="d-flex flex-column pl-4 pr-4 pt-4" v-if="detailLoaded">
    <h5 class="text-h5 font-weight-medium mb-4">주문/결제 정보</h5>
    <div class="pa-0">
      <v-container class="grey lighten-4 pt-2 pb-2 pl-1 pr-1">
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">주문번호</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderId }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">주문일시</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderInfo.paidAt }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">상품명</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderInfo.productName }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">옵션</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderInfo.option.name }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">결제 금액</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{
              this.$toMoney(this.orderInfo.option.price)
            }}원
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">주문 상태</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderStatusText }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">배송지 주소</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1"> {{ this.orderInfo.shipping.fullAddress }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">이름</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderInfo.userName }}</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="font-weight-bold pl-2 pr-0 pt-2 pb-2">휴대폰 번호</v-col>
          <v-col cols="9" class="text-right pl-0 pr-2 pt-1 pb-1">{{ this.orderInfo.phoneNumber }}</v-col>
        </v-row>
      </v-container>
    </div>
    <div class="pt-0 pb-4 pl-0 pr-0 button-area">
      <v-btn v-if="this.orderStatusText === '주문 완료' && this.orderCancelAvailable" @click="cancelRequest"
             class="text-body-1 font-weight-bold pa-6 mb-4"
             block
             elevation="2"
             outlined
             rounded>
        결제 취소
      </v-btn>
      <v-btn v-if="this.orderStatusText === '주문 완료' && !this.orderCancelAvailable"
             class="text-body-1 font-weight-bold pa-6 mb-4"
             block
             elevation="2"
             outlined
             disabled
             rounded>
        결제 취소
      </v-btn>
      <v-btn @click="moveToBack"
             class="text-body-1 font-weight-bold pa-6"
             color="primary"
             block
             rounded>
        닫기
      </v-btn>
    </div>
    <OrderCancelBottomSheet/>
    <v-bottom-sheet v-model="cancelCompletePopup" inset max-width="500">
      <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
        <div class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-icon color="black" class="pa-4" @click="cancelCompletePopup = false">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4">
          <div class="text-h6 font-weight-bold text-center">결제 취소 완료</div>
          <div class="pt-2 pb-8">결제 취소가 정상적으로 처리되었습니다.</div>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4 pb-6">
          <v-btn @click="refreshPage"
                 color="secondary"
                 class="text-body-1 font-weight-bold pa-6"
                 block rounded>확인
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import OrderCancelBottomSheet from '../../components/common/popup/bottom-sheet/OrderCancelBottomSheet'
export default {
  name: 'OrderDetail',
  components: { OrderCancelBottomSheet },
  props: {
    orderId: {
      type: String
    },
    phoneNumber: {
      type: String
    }
  },
  data () {
    return {
      orderInfo: {
        id: this.orderId,
        productName: '',
        option: {
          name: '',
          price: this.$toMoney(0)
        },
        shipping: {
          address: '',
          extraAddress: '',
          postCode: '',
          fullAddress: ''
        },
        userName: '',
        phoneNumber: '',
        orderStatus: '',
        paidAt: ''
      },
      cancelCompletePopup: false,
      detailLoaded: false,
      orderCancelAvailable: false
    }
  },
  computed: {
    orderStatusText () {
      let text = ''
      switch (this.orderInfo.orderStatus) {
        case 'paid':
          text = '주문 완료'
          break
        case 'cancelled':
          text = '주문 취소'
          break
        default:
          break
      }
      return text
    }
  },
  created () {
    console.log(this.phoneNumber)
    console.log(this.orderId)
    console.log(this.CONSTANTS.orderCancelAvailable)
    this.$on('cancelRequestEmit', this.cancelRequestEmit)
    if (this.phoneNumber === null || this.orderId === null) {
      alert('정보를 정확히 입력해 주세요.')
      this.$router.back()
    } else {
      this._getOrderDetail().then((res) => {
        if (res.data.data) {
          this.detailLoaded = true
          console.log(res.data.data)
          const responseData = res.data.data
          this.orderInfo = {
            id: responseData.billing.merchantUid,
            productName: responseData.billing.name,
            option: {
              name: responseData.optionSelection.selection,
              price: this.$toMoney(responseData.billing.paidAmount)
            },
            shipping: {
              address: responseData.billing.buyerAddr,
              extraAddress: '',
              postCode: '',
              fullAddress: responseData.billing.buyerAddr
            },
            userName: responseData.billing.buyerName,
            phoneNumber: responseData.billing.buyerTel,
            orderStatus: responseData.billing.status,
            paidAt: responseData.billing.paidAt
          }
          console.log(responseData.optionSelection)
          // TODO : 상품 아이디 체크가 아니라 api 단에서 주문 취소 가능 여부 내려오도록 처리해야함 지금은 임시로 막음
          this.orderCancelAvailable = this.CONSTANTS.orderCancelAvailable && (responseData.optionSelection.productId > 3)
          console.log(this.orderCancelAvailable)
          console.log(this.orderInfo)
        } else {
          alert('주문 내역 조회 실패')
          this.moveToBack()
        }
      }).catch((err) => {
        console.log(err)
        alert('주문 내역 조회 실패')
        this.moveToBack()
        if (err.response.status === 404 && err.response.data.status === 'NOT_FOUND') {
          console.log('주문 내역 없음')
        }
      })
    }
  },
  methods: {
    moveToBack () {
      this.$router.back()
    },
    cancelRequest () {
      this.$emit('showLeavePopup')
    },
    cancelRequestEmit () {
      this._postCancelRequest().then((res) => {
        if (res.data.statusCode === '200' && res.data.data) {
          // alert('취소 완료되었습니다.')
          this.cancelCompletePopup = true
        } else {
          alert('결제 취소 실패')
        }
      }).catch((error) => {
        console.log(error)
        if (error.response.status === 404) {
          alert('결제 이력이 없습니다.')
        }
      })
    },
    refreshPage () {
      this.$router.go(0)
    },
    _getOrderDetail () {
      return this.axios.get(this.API_PATH + '/nonmember/payInfo/check', {
        params: {
          orderCode: this.orderId,
          buyerTel: this.phoneNumber
        }
      })
    },
    _postCancelRequest () {
      return this.axios.post(this.API_PATH + '/nonmember/payInfo/cancel', {
        orderCode: this.orderId,
        buyerTel: this.phoneNumber
      })
    }
  }
}
</script>

<style scoped>
.button-area {
  margin-top: 15vh;
}
</style>
