<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-4 pa-0" align="center">
        <v-avatar color="indigo" v-if="iconType === 'text'">
          <span class="white--text text-h6">0.8L</span>
        </v-avatar>
        <v-avatar v-if="iconType === 'image'">
          <!-- <v-img :src="iconUrl" eager aspect-ratio="1"/> -->
          <img src="../../assets/images/onlif/온리프_로고.jpg" eager aspect-ratio="1" style="border:1px solid black;"/>
        </v-avatar>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <span class="black--text font-weight-bold">{{ this.name }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileIcon',
  props: {
    name: {
      type: String,
      require: true
    },
    iconType: {
      type: String,
      default: 'text'
    },
    iconUrl: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    //
  })
})
</script>
