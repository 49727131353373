<template>
  <v-container>
    <v-row class="pt-4">
      <v-col class="text-center">
        <img class="mt-8 mb-4" src="../../assets/images/purchase/purchase-success.svg"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <span class="text-h5 font-weight-bold">결제가 <span
          class="text-h5 font-weight-bold blue--text">완료</span>되었습니다.</span>
      </v-col>
    </v-row>
    <div class="pl-4 pr-4">
      <v-container class="grey lighten-4 mt-10 mb-10 pl-1 pr-1">
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">주문번호</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.orderId }}</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">상품명</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.orderInfo.productName }}</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">옵션</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.orderInfo.option.name }}</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">결제 금액</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.$toMoney(this.orderInfo.option.price) }}원</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">배송지 주소</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all"> {{ this.orderInfo.shipping.fullAddress }}</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">이름</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.orderInfo.userName }}</v-col>
        </v-row>
        <v-row class="pl-4 pr-4 ma-0">
          <v-col cols="4" class="font-weight-bold pl-0 pr-0 pt-2 pb-2">휴대폰 번호</v-col>
          <v-col class="text-right pl-0 pr-0 pt-1 pb-1 keep-all">{{ this.orderInfo.phoneNumber }}</v-col>
        </v-row>
      </v-container>
    </div>
    <div class="pa-4">
      <v-btn @click="moveToProductDetail"
             class="text-body-1 font-weight-bold pa-6"
             color="primary"
             block
             rounded>
        상품 보러가기
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PurchaseSuccess',
  props: {
    orderId: {
      type: String
    }
  },
  data () {
    return {
      orderInfo: {
        id: this.orderId,
        productName: '',
        productId: 1,
        option: {
          name: '',
          price: 0
        },
        shipping: {
          address: '',
          extraAddress: '',
          postCode: '',
          fullAddress: ''
        },
        userName: '',
        phoneNumber: ''
      }
    }
  },
  created () {
    this._getPurchaseInfo(this.orderId).then((response) => {
      if (response.data !== '') {
        this.orderInfo.productName = response.data.billing.name
        this.orderInfo.option.name = response.data.optionSelection.selection
        this.orderInfo.option.price = response.data.billing.paidAmount
        this.orderInfo.shipping.fullAddress = response.data.billing.buyerAddr
        this.orderInfo.userName = response.data.billing.buyerName
        this.orderInfo.phoneNumber = response.data.billing.buyerTel
        this.orderInfo.productId = response.data.optionSelection.productId
        this.$sendGAEvent('CO-BUYING_PURCHASE_CMP', {})
        this.$sendGAEvent('purchase', {
          item_id: 1, // TODO: 추후 productId api로 받아서 처리할 것
          item_name: this.orderInfo.productName,
          transaction_id: this.orderId,
          shipping: 0,
          currency: 'KRW',
          price: this.orderInfo.option.price,
          quantity: 1,
          value: this.orderInfo.option.price,
          item_variant: this.orderInfo.option.name
        })
      } else {
        this.orderInfo = {}
        alert('결제 정보 로딩 실패')
        this.$router.push({ path: '/' })
      }
    }).catch((error) => {
      console.log(error)
    })
  },
  methods: {
    moveToProductDetail () {
      this.$router.push({ path: '/product/detail/' + this.orderInfo.productId })
    },
    _getPurchaseInfo (orderId) {
      return this.axios.get(this.API_PATH + '/nonmember/payInfo/' + orderId, { params: { type: 'p' } })
    }
  }
}
</script>

<style scoped>
.keep-all {
  word-break: keep-all;
}
</style>
