import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PurchaseApply from '../views/purchase/PurchaseApply'
import ProductDetail from '../views/product/detail/ProductDetail'
import ProductDetailOnlif from '../views/product/detail/ProductDetailOnlif'
import PageNotFound from '../views/error/PageNotFound'
import PurchaseSuccess from '../views/purchase/PurchaseSuccess'
import PurchaseCancel from '../views/purchase/PurchaseCancel'
import CSMain from '../views/cs/CSMain'
import OrderTracking from '../views/order/OrderTracking'
import OrderDetail from '../views/order/OrderDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/purchase/apply/:productId',
    name: 'PurchaseApply',
    component: PurchaseApply,
    props: route => ({ productInfo: route.params.productInfo, selectedOption: route.params.selectedOption }),
    beforeEnter: (to, from, next) => {
      // 구매 페이지 새로고침 시 파라미터 없어서 메인으로 보냄
      if (to.params.selectedOption) {
        next()
      } else {
        next(from)
      }
    }
  },
  {
    path: '/product/detail/:productId',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/product/onlif',
    name: 'ProductDetailOnlif',
    component: ProductDetailOnlif
  },
  {
    path: '/purchase/success',
    name: 'PurchaseSuccess',
    component: PurchaseSuccess,
    props: route => ({ orderId: route.query.orderId || null })
  },
  {
    path: '/purchase/cancel',
    name: 'PurchaseCancel',
    component: PurchaseCancel,
    props: route => ({ orderId: route.query.orderId || null })
  },
  {
    path: '/customer-service',
    name: 'CSMain',
    component: CSMain
  },
  {
    path: '/order/tracking',
    name: 'OrderTracking',
    component: OrderTracking
  },
  {
    path: '/order/detail/:orderId/:phoneNumber',
    name: 'OrderDetail',
    component: OrderDetail,
    props: route => ({ orderId: route.params.orderId || null, phoneNumber: route.params.phoneNumber || null })
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
