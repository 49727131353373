<template>
  <v-container class="text-center fill-height flex-column pt-16">
    <v-avatar rounded size="60" class="mt-16 mb-8">
      <img src="../../assets/images/icon/logo.svg"/>
    </v-avatar>
    <h2>페이지를 찾을 수 없어요.</h2>
    <span class="text-subtitle-2 mt-4 mb-16">원하시는 서비스로 이동해주세요.</span>
    <div class="button-area">
      <v-btn elevation="2"
             color="primary"
             class="text-body-1 font-weight-bold pa-6"
             :to="{name: 'OrderTracking'}"
             block rounded :link="true">
        비회원 주문확인
      </v-btn>
      <v-btn elevation="2"
             color="primary"
             class="text-body-1 font-weight-bold pa-6 mt-2"
             block rounded :link="true" :to="{ name:'CSMain' }">
        공구 주문/배송 확인
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.button-area {
  margin-top: 18vh;
  width: 100%;
}
</style>
