<template>
  <v-row class="fixed-area-bottom">
    <!-- 옵션 있을 경우 bottom sheet -->
    <v-bottom-sheet v-if="this.hasOptions && !this.allSoldOut" v-model="sheet" inset max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" class="text-body-1 font-weight-bold pa-6"
               color="primary"
               block
               fab
               elevation="2"
               @click.stop="showOptionSheet"
               rounded>
          구매하기
        </v-btn>
      </template>
      <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
        <div class="d-flex justify-center">
          <v-spacer></v-spacer>
          <span class="text-body-1 font-weight-bold black--text pa-4">옵션 선택</span>
          <v-spacer></v-spacer>
          <v-icon color="black" class="pr-2" @click="sheet = !sheet">mdi-close</v-icon>
        </div>
        <div class="d-flex flex-column mb-4 overflow-auto option-list-area" v-for="option in options"
             v-bind:key="option.id">
          <v-radio-group v-model="selectedOption">
            <OptionSelectionComponent v-for="selection in option.optionSelections" :key="selection.id"
                                      :selection="selection"></OptionSelectionComponent>
          </v-radio-group>
        </div>
        <div class="d-flex justify-center pl-4 pr-4 pb-6">
          <!-- 옵션 있을 경우 구매하기 버튼 -->
          <v-btn @click="goToPurchase"
                 color="secondary"
                 fab
                 class="text-body-1 font-weight-bold pa-6"
                 block rounded>구매하기
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <!-- 옵션 없을 경우 바로 구매하기 버튼 -->
    <template v-if="!this.hasOptions && !this.allSoldOut">
      <v-btn @click="goToPurchase" class="text-body-1 font-weight-bold pa-6"
             color="primary"
             block
             fab
             elevation="2"
             rounded>
        구매하기
      </v-btn>
    </template>

    <template v-if="this.allSoldOut">
      <v-btn class="text-body-1 font-weight-bold pa-6 disabled-btn"
             block
             fab
             elevation="2"
             color="grey"
             disabled
             style="background-color: #AAABAF!important;color: #FFFFFF!important;"
             rounded>
        완판되었습니다.
      </v-btn>
    </template>
  </v-row>
</template>

<script>
import Vue from 'vue'
import OptionSelectionComponent from '../../../../components/product/option/OptionSelectionComponent'
import _ from 'lodash'

export default Vue.extend({
  name: 'FloatingBuyButton',
  components: { OptionSelectionComponent },
  props: {
    options: Array,
    allSoldOut: Boolean,
    productInfo: Object
  },
  computed: {
    hasOptions: function () {
      return this.productInfo.productOptions[0].isRegOption
    }
  },
  created () {
    this.$parent.$on('hideOptionSheet', this.hideOptionSheet)
  },
  data () {
    return {
      sheet: false,
      selectedOption: {}
    }
  },
  methods: {
    showOptionSheet () {
      this.sheet = !this.sheet
      this.$sendGAEvent('CO-BUYING_BUYBTN', {
        productId: this.productInfo.id,
        name: this.productInfo.name
      })
    },
    goToPurchase () {
      this.$parent.$emit('updateCanLeaveSite', true)
      this.$sendGAEvent('CO-BUYING_OPTION', {
        productId: this.productInfo.id,
        name: this.productInfo.name
      })
      if (!this.hasOptions) {
        this.selectedOption = this.productInfo.productOptions[0].optionSelections[0]
      }
      if (!_.isEmpty(this.selectedOption)) {
        this.$router.push({
          name: 'PurchaseApply',
          params: {
            selectedOption: this.selectedOption,
            productInfo: this.productInfo
          }
        })
      } else {
        alert('옵션을 선택해 주세요.')
      }
    },
    hideOptionSheet () {
      this.sheet = false
    }
  }
})
</script>

<style scoped>
.fixed-area-bottom {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 12px;
  z-index: 1;
  top: auto;
}
.option-list-area {
  max-height: 50vh;
}
</style>
