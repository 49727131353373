<template>
  <div class="text-center fill-height flex-column">
    <v-avatar rounded size="60" class="mt-16 mb-4">
      <img src="../../assets/images/icon/logo.svg"/>
    </v-avatar>
    <h2 class="mb-8">비회원 주문 조회</h2>
    <v-form ref="form" v-model="valid" lazy-validation class="full-width-form">
      <div class="pl-4 pr-4">
        <v-text-field outlined
                      v-model="userInputData.phoneNumber"
                      :rules="userInputData.phoneNumberRules"
                      label="휴대폰번호"
                      hint="주문시 입력한 휴대폰 번호를 입력해 주세요."
                      persistent-hint
                      required></v-text-field>
        <v-text-field outlined
                      v-model="userInputData.orderId"
                      :rules="userInputData.orderIdRules"
                      label="주문번호"
                      hint="주문번호는 주문 시 발송된 카카오톡에서 확인해 주세요."
                      persistent-hint
                      required></v-text-field>
        <v-btn elevation="2"
               color="primary"
               class="text-body-1 font-weight-bold pa-6 mt-4"
               @click="goToOrderDetail"
               block rounded>주문 확인하기
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'OrderTracking',
  data: () => ({
    valid: true,
    userInputData: {
      phoneNumber: '',
      phoneNumberRules: [
        v => !!v || '휴대폰번호를 입력해주세요.',
        v => /^[0-9]+$/.test(v) || '숫자만 입력해주세요.(\'-\'제외)'
      ],
      orderId: '',
      orderIdRules: [
        v => !!v || '주문번호를 입력해주세요.',
        v => /^[0-9]+$/.test(v) || '숫자만 입력해주세요.(\'-\'제외)'
      ]
    }
  }),
  methods: {
    goToOrderDetail () {
      if (this.valid) {
        this.$router.push({
          name: 'OrderDetail',
          params: { orderId: this.userInputData.orderId, phoneNumber: this.userInputData.phoneNumber }
        })
      }
    }
  }
}
</script>

<style scoped>
.full-width-form {
  width: 100%;
  margin: 15vh auto;
}
</style>
