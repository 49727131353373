import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#458EF6',
        secondary: '#000000',
        accent: '#458EF6',
        error: '#FF3D3E',
        info: '#458EF6',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
})
