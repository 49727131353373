<template>
  <div class="grey lighten-5 pt-2 pl-4 pr-4 pb-16">
    <div class="grey--text text-subtitle-2 pl-0 pr-0">
      1. 취소규정  <br/>
      - 온리프성형외과 금액권 구매에 따른 취소규정 <br/>
      - : 취소는 결제 후 7일 이내에 가능합니다.<br/>
      - : 금액권 사용기한 구매일로부터 1년 <br/><br/>
      1. 환불규정<br/>
      - 일부 금액 사용 후 남은금액은 환불이 불가합니다. 금액에 상응하는 시, 수술로만 사용 가능합니다. <br/>
      - 양도를 원하실 경우 1회, 남은 사용기간 내 사용하는 조건으로 양도가 가능합니다. <br/>
    </div>
    <div class="ma-0 pt-4">
      <div class="d-flex pl-0 pr-0">
        <v-btn text class="font-weight-bold grey--text text--darken-3 pl-0 pr-0 mr-2 text-body-1"
               @click.stop="showTermsOfServicePopup">서비스 이용약관</v-btn>
        <v-btn text class="font-weight-bold grey--text text--darken-3 pl-0 pr-0 text-body-1"
               @click.stop="showPrivacyPopup">개인정보 처리방침
        </v-btn>
        <div class="flex-grow-1"></div>
      </div>
    </div>
    <div class="pt-2 pb-6">
      <!-- <div class="grey--text text-subtitle-2 pl-0 pr-0">
        서울특별시 강남구 영동대로 511, 405호(삼성동, 트레이드타워)<br/>
        E-mail <a class="link" href="mailto:help@08liter.com" target="_blank">help@08liter.com</a><br/>
        Tel 02 6932 0808 | Fax 0303 3446 3332<br/>
        사업자등록번호 120-88-23402<br/>
        통신판매 신고번호 2015-서울강남-00755호<br/>
        개인 정보 보호 책임자 | 김태윤<br/>
      </div> -->
      <div class="grey--text text-subtitle-2 pl-0 pr-0">
        상호명 : 온리프성형외과의원 <br/>
        대표자명 : 이재운 <br/>
        사업자등록번호 : 450-37-00837 <br/>
        사업장 소재지 : 서울특별시 서초구 서초대로77길 17, 7층 2호 <br/>
        대표전화 : 1833-8171 <br/>
        대표 이메일 : bo_tax@beston.kr <br/>
      </div>
    </div>
    <PrivacyBottomSheet/>
    <TermsOfServiceBottomSheet/>
  </div>
</template>

<script>
import PrivacyBottomSheet from '../popup/bottom-sheet/PrivacyBottomSheet'
import TermsOfServiceBottomSheet from '../popup/bottom-sheet/TermsOfServiceBottomSheet'

export default {
  name: 'Footer',
  components: {
    TermsOfServiceBottomSheet,
    PrivacyBottomSheet
  },
  methods: {
    showPrivacyPopup () {
      this.$emit('showPrivacyBottomSheet')
    },
    showTermsOfServicePopup () {
      this.$emit('showTermsOfServiceBottomSheet')
    }
  }
}
</script>

<style scoped>
a.link {
  color: #9E9E9E;
  text-decoration: none;
}
</style>
