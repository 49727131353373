<template>
  <div class="grey lighten-4 pt-2 pb-6 fill-height">
    <profile-icon icon-type="image" :icon-url="icon" name="오늘의 공구 핫딜"/>
    <div class="overflow-y-auto" v-if="productList.length > 0" style="max-height: 78vh;">
      <v-card class="ma-4" v-for="link in productList" :key="link.id">
        <router-link class="text-decoration-none black--text"
                     :to="{name: 'ProductDetail', params: {productId: link.id}}">
          <div class="d-flex pa-2 align-center">
            <div class="flex-grow-0">
              <v-avatar size="60" tile>
                <v-img height="100%"
                       :src="getMainThumbnail(link)"/>
              </v-avatar>
            </div>
            <div class="pt-0 pb-0 pl-4 pr-4 text-body-2 flex-fill">{{ link.name }}</div>
          </div>
        </router-link>
      </v-card>
    </div>
    <div class="pl-4 pr-4 mt-4 mb-4">
      <v-btn elevation="2"
             color="primary"
             class="text-body-1 font-weight-bold pa-6"
             :to="{name: 'OrderTracking'}"
             block rounded :link="true">
        비회원 주문확인
      </v-btn>
    </div>
  </div>
</template>

<script>
import ProfileIcon from '../components/member/ProfileIcon'

export default {
  name: 'Home',
  components: {
    ProfileIcon
  },
  data: () => ({
    icon: require('@/assets/images/icon/logo.svg'),
    productList: []
  }),
  created () {
    this._getProductLists().then((response) => {
      this.productList = response.data.data
    })
  },
  methods: {
    _getProductLists () {
      return this.axios.get(this.API_PATH + '/nonmember/productInfo/list')
    },
    getMainThumbnail (link) {
      if (link.productImageList.length > 0) {
        return link.productImageList.find(image => { return image.imageCode === '0301' }).imageURI + '320'
      } else {
        return this.icon
      }
    }
  }
}
</script>
