<template>
  <div class="d-flex pt-3 pb-3 options-wrap" :class="{'sold-out': selection.optionStock == 0}">
    <div class="flex-shrink-1 flex-grow-1 text-left pl-4">
      <div class="text-body-1 font-weight-medium pb-2">{{ selection.selection }}</div>
      <div class="align-center d-inline-flex">
        <span class="sale-percent font-weight-medium mr-2">{{
            this.$computeSalePercent(selection.originPrice, selection.optionPrice)
          }}%</span>
        <span class="font-weight-medium-bold mr-2">{{ this.$toMoney(selection.optionPrice) }}원</span>
        <span class="grey--text font-weight-light text-decoration-line-through mr-2">{{ this.$toMoney(selection.originPrice) }}원</span>
        <v-chip class="pl-1 pr-1" x-small>무료 배송</v-chip>
      </div>
    </div>
    <div class="flex-shrink-0 pr-4">
      <v-radio v-if="selection.optionStock > 0" :value="selection"></v-radio>
      <span class="red--text font-weight-bold pa-2" v-if="selection.optionStock === 0">품절</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionSelectionComponent',
  props: {
    selection: Object
  }
}
</script>

<style lang="scss" scoped>
.options-wrap {
  border-top: 1px solid #F5F5F5;
  &:last-child {
    border-bottom: 1px solid #F5F5F5;
  }
}
.sold-out {
  background-color: #F5F5F5;
  color: #AAABAF!important;
  .sale-percent {
    color: #AAABAF;
  }
}
.sale-percent {
  color: #F44336;
}
</style>
