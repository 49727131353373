<template>
  <v-app>
    <v-main class="main fill-height overflow-y-auto">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style scoped>
.main {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}
</style>
