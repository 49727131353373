<template>
  <div class="d-flex align-center pl-4 pr-4">
    <div class="flex-grow-0 flex-shrink-0"><!-- 상품 썸네일 -->
      <div class="mt-4 mb-4 overflow-hidden" style="width: 60px; height: 60px;">
        <v-img width="60" height="60" aspect-ratio="1" :src="this.thumbnail"/>
      </div>
    </div>
    <div class="flex-grow-1 flex-shrink-0 pl-4 pt-2 pb-2" style="max-width: calc(100% - 60px);"><!-- 상품명, 옵션명 -->
      <div class="d-inline-flex flex-column">
        <span class="text-body-1 font-weight-bold word-break-keep">{{ this.name }}</span>
        <span v-if="this.optionName" class="text-body-2 word-break-keep">{{ this.optionName }} ({{ this.$toMoney(this.salePrice) }}원/{{ this.$computeSalePercent(this.originPrice, this.salePrice) }}%할인)</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductInfoCard',
  props: {
    thumbnail: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: true
    },
    optionName: {
      type: String,
      optional: true
    },
    originPrice: Number,
    salePrice: Number
  }
}
</script>
<style>
.word-break-keep {
  word-break: keep-all;
}
</style>
