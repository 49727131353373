<template>
  <div class="grey lighten-4 pt-2 pb-6 fill-height">
    <profile-icon icon-type="image" :icon-url="icon" name="0.8L 공구 주문/배송 CS"/>
    <v-card class="ma-4" v-for="link in csLinkList" :key="link.id">
      <a class="text-decoration-none black--text" :href="link.url" target="_blank">
        <div class="d-flex pa-2 align-center">
          <div class="flex-grow-0">
            <v-avatar size="60" tile>
              <v-img height="100%" :src="link.thumbnail"/>
            </v-avatar>
          </div>
          <div class="pt-0 pb-0 pl-4 pr-4 text-body-2 flex-fill">{{ link.title }}</div>
        </div>
      </a>
    </v-card>
  </div>
</template>

<script>
import ProfileIcon from '../../components/member/ProfileIcon'

export default {
  name: 'CSMain',
  components: {
    ProfileIcon
  },
  data: () => ({
    icon: require('@/assets/images/icon/logo.svg'),
    csLinkList: [
      {
        id: 4,
        thumbnail: 'https://img.08liter.com/release/product/9f0aba69e1ee4d09b4dcaa7562a788ee1920',
        title: '[산그리메] 첨가물 ZERO! 간편하게 즐기는, 산그리메 국내산차 10종 中 택 1 (100티백)',
        url: 'mailto:sangrimetea@naver.com?subject=[삼윤맘 x 0.8L] “[산그리메] 첨가물 ZERO! 간편하게 즐기는, 산그리메 국내산차 10종 中 택 1 (100티백)” 공구 상품 문의 드립니다.' +
          '&body=주문/배송 문의 내용을 작성해 주세요.%0D%0A%0D%0A' +
          '- 구매자명 :%0D%0A' +
          '- 주문번호 :%0D%0A' +
          '- 문의 사항 :'
      }
    ]
  })
}
</script>

<style scoped>

</style>
