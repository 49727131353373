const methods = {
  // 숫자 포맷팅 함수
  toMoney: (value) => {
    return value.toLocaleString()
  },
  gaEventSend: (action, eventParams) => {
    if (process.env.NODE_ENV !== 'production') {
      eventParams.debug_mode = true
    }
    if (typeof window.gtag === 'function') {
      window.gtag('event', action, eventParams)
    }
  },
  computeSalePercent: (originPrice, salePrice) => {
    if (originPrice === 0) {
      // 0으로 나누는 경우
      return Math.floor(((originPrice - salePrice) / 1) * 100)
    } else {
      return Math.floor(((originPrice - salePrice) / originPrice) * 100)
    }
  }
}

const constants = {
  API: () => {
    let path = '/'
    if (process.env.NODE_ENV === 'production') {
      path = 'https://payment.08liter.com'
    } else if (process.env.NODE_ENV === 'development') {
      // path = 'https://qa-pay.08liter.com'
      path = 'https://payment.08liter.com'
    }
    return path
  },
  CONSTANTS: () => {
    return {
      orderCancelAvailable: true
    }
  }
}

export default {
  install (Vue) {
    Vue.prototype.$toMoney = methods.toMoney
    Vue.prototype.$sendGAEvent = methods.gaEventSend
    Vue.prototype.API_PATH = constants.API()
    Vue.prototype.CONSTANTS = constants.CONSTANTS()
    Vue.prototype.$computeSalePercent = methods.computeSalePercent
  }
}
