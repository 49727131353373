<template>
  <v-container class="pa-0">
    <div class="fill-height" v-show="loaded">
      <profile-icon :name="productInfo.celeb.nickname + ' x 0.8L'" :icon-url="getCelebThumbnail(productInfo)" icon-type="image"/>
      <div style="position: relative;">
        <div>
          <v-img :src="getMainImage(productInfo)"/>
        </div>
        <div class="d-flex align-center text-center sold-out-layer" v-if="productInfo.allSoldOut">
          <div class="white--text font-weight-bold text-h4 flex-fill">SOLD OUT</div>
        </div>
      </div>
      <div class="mt-2 mb-2 pl-4 pr-4">
        <div>
          <span class="text-subtitle-1 font-weight-bold">{{ productInfo.name }}</span>
        </div>
        <div>
        <span class="red--text font-weight-bold">{{
            this.$computeSalePercent(productInfo.price, productInfo.gonguPrice)
          }}%</span>
          <span class="black--text font-weight-bold pl-1">{{ this.$toMoney(productInfo.gonguPrice) }}원</span>
          <span
            class="grey--text text--lighten-1 text-caption
          text-decoration-line-through pl-1">{{ this.$toMoney(productInfo.price) }}원</span>
        </div>
        <div class="ma-0 pt-0 pb-0 d-flex justify-start align-stretch">
          <span class="text-caption mr-1"><img :src="deliveryIcon"></span>
          <span class="text-caption" style="line-height: 25px;">무료 배송</span>
        </div>
      </div>
      <div>
        <div v-for="image in getDetailImages(productInfo)" :key="image.position">
          <v-img :src="image.imageURI+'1920'"/>
        </div>
      </div>
      <FloatingBuyButton :options="productInfo.productOptions" :allSoldOut="productInfo.allSoldOut" :productInfo="productInfo"/>
      <LeaveBottomSheet template-type="productDetail"/>
      <Footer/>
    </div>
  </v-container>
</template>

<script>

import ProfileIcon from '../../../components/member/ProfileIcon'
import FloatingBuyButton from './components/FloatingBuyButton'
import LeaveBottomSheet from '../../../components/common/popup/bottom-sheet/LeaveBottomSheet'
import Footer from '../../../components/common/layout/TheFooter'

export default {
  name: 'ProductDetail',
  components: {
    Footer,
    LeaveBottomSheet,
    ProfileIcon,
    FloatingBuyButton
  },
  data: () => ({
    routeTo: '',
    canLeaveSite: false,
    loaded: false,
    deliveryIcon: require('@/assets/images/icon/ic_shipping_w.svg'),
    productInfo: {
      id: 2,
      name: '[QA] 테스트 구매',
      price: 15000,
      currency: 'KRW',
      divisionCode: 'DELV',
      useYn: 'Y',
      createdAt: '2022-02-24 06:24:36',
      updatedAt: '2022-02-24 06:24:36',
      stock: 2,
      startAt: '2022-02-20 06:24:36',
      endAt: '2022-03-02 06:24:36',
      gonguPrice: 2,
      celeb: {
        celebId: 541766,
        nickname: '',
        thumbnailURI: 'https://img.08liter.com/dev/member/c8f6efcfc1ab423098cbe6b06b70312b'
      },
      productImageList: [{
        imageCode: '0301',
        position: 0,
        imageURI: 'https://img.08liter.com/dev/product/ff6d207dfea64661aa4725bed031faaf'
      }, {
        imageCode: '0302',
        position: 1,
        imageURI: 'https://img.08liter.com/dev/product/1d33e456b62c486080ccaf4e8443e470'
      }, {
        imageCode: '0302',
        position: 2,
        imageURI: 'https://img.08liter.com/dev/product/1491538dc7de452fb6187bb0ae8b365c'
      }, {
        imageCode: '0302',
        position: 3,
        imageURI: 'https://img.08liter.com/dev/product/d4d86c4f03744859b67a97f16f1bedb0'
      }],
      productOptions: [{
        id: 4,
        productId: 2,
        optionCode: '0701',
        position: 0,
        title: '-',
        createdAt: '2022-02-24 06:33:21',
        updatedAt: '2022-02-24 08:36:33',
        useYn: 'Y',
        optionSelections: [{
          id: 5,
          productOptionId: 4,
          productId: 2,
          selection: '[QA] 테스트 구매',
          optionStock: 2,
          createdAt: '2022-02-24 06:51:49',
          updatedAt: '2022-02-24 08:12:13',
          useYn: 'Y',
          optionPrice: 2
        }],
        isRegOption: false
      }],
      allSoldOut: false
    }
  }),
  created () {
    this.productInfo.id = this.$route.params.productId
    this.$on('updateCanLeaveSite', this.updateCanLeaveSite)
    this.fetchPurchaseStatus()
    this.$sendGAEvent('CO-BUYING_PAGEVIEW', {
      productId: this.productInfo.id,
      name: this.productInfo.name
    })
    // 가격에 공구가 넣었음
    this.$sendGAEvent('view_item', {
      item_id: this.productInfo.id,
      item_name: this.productInfo.name,
      currency: this.productInfo.currency,
      price: this.productInfo.gonguPrice,
      quantity: 1
    })
  },
  mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  },
  beforeRouteLeave (to, from, next) {
    if (this.canLeaveSite) {
      next()
    } else {
      this.routeTo = to
      this.$emit('hideOptionSheet')
      this.$emit('showLeavePopup')
      next(false)
    }
  },
  watch: {
    // 라우트가 변경되면 메소드를 다시 호출합니다
    $route: 'fetchPurchaseStatus'
  },
  methods: {
    updateCanLeaveSite (canLeaveSite) {
      this.canLeaveSite = canLeaveSite
      if (this.canLeaveSite && this.routeTo) {
        this.$router.replace(this.routeTo)
      }
    },
    beforeUnloadHandler (event) {
      if (this.canLeaveSite) return
      event.preventDefault()
      event.returnValue = ''
    },
    fetchPurchaseStatus () {
      this._getProductDetail(this.$route.params.productId).then((response) => {
        this.productInfo = response.data.data
        this.allSoldOut = this.productInfo.allSoldOut
        this.loaded = true
      }).catch((error) => {
        console.log(error)
        alert('상품 정보 없음')
        this.updateCanLeaveSite(true)
        // 뒤로 보내기
        this.$router.back()
      })
    },
    _getProductDetail (id) {
      return this.axios.get(this.API_PATH + '/nonmember/productInfo/' + id)
    },
    getMainImage (info) {
      if (info.productImageList.length > 0) {
        return info.productImageList.find(image => {
          return image.imageCode === '0301'
        }).imageURI + '1920'
      }
      return ''
    },
    getDetailImages (info) {
      return info.productImageList.filter(image => {
        return image.imageCode === '0302'
      })
    },
    getCelebThumbnail (info) {
      return info.celeb.thumbnailURI + '320'
    }
  }
}
</script>

<style scoped>
.sold-out-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(125, 125, 125, 0.5);
}
</style>
