import { render, staticRenderFns } from "./OptionSelectionComponent.vue?vue&type=template&id=0fb64942&scoped=true&"
import script from "./OptionSelectionComponent.vue?vue&type=script&lang=js&"
export * from "./OptionSelectionComponent.vue?vue&type=script&lang=js&"
import style0 from "./OptionSelectionComponent.vue?vue&type=style&index=0&id=0fb64942&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb64942",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VChip,VRadio})
