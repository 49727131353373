<template>
  <div class="pt-2 pb-6">
    <v-btn @click.stop="onMapLoaded"
           class="text-body-1 font-weight-bold pa-6"
           color="secondary"
           block
           elevation="2"
           rounded>
      주소 찾기
    </v-btn>
    <v-dialog eager v-model="sheet" value="sheet" max-width="500">
      <v-sheet class="text-center">
        <v-card min-height="500px">
          <div class="d-flex" id="wrap">
          </div>
        </v-card>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'AddressSearchBottomSheet',
  data () {
    return {
      sheet: false
    }
  },
  methods: {
    showAddressIFrame: function (data) {
      var addr = ''
      var extraAddr = ''
      if (data.userSelectedType === 'R') {
        addr = data.roadAddress
      } else {
        addr = data.jibunAddress
      }
      if (data.userSelectedType === 'R') {
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraAddr += data.bname
        }
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName)
        }
        if (extraAddr !== '') {
          extraAddr = ' (' + extraAddr + ')'
        }
      }
      return {
        address: addr,
        extraAddress: extraAddr,
        postCode: data.zonecode
      }
    },
    onMapLoaded () {
      this.sheet = true
      const vm = this
      const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
      if (window && window.daum) { // 다음 스크립트가 로딩 완료 되었을때
        const elementWrap = document.getElementById('wrap')
        new window.daum.Postcode({
          oncomplete: function (data) {
            const addressResult = vm.showAddressIFrame(data)
            // iframe을 넣은 element를 안보이게 한다.
            // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
            elementWrap.style.display = 'none'
            elementWrap.style.height = '0px'
            // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
            document.body.scrollTop = currentScroll
            // 다음 프레임 처리 완료 후 이벤트 발생
            vm.sheet = false
            vm.$emit('addressUpdated', addressResult)
          },
          width: '100%',
          height: '100vh'
        }).embed(elementWrap)

        // iframe을 넣은 element를 보이게 한다.
        elementWrap.style.display = 'block'
      }
    }
  }
}
</script>
