<template>
  <v-bottom-sheet v-model="leavePopup" inset max-width="500">
    <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
      <div class="d-flex justify-center">
        <v-spacer></v-spacer>
        <v-icon color="black" class="pa-4" @click="closeLeavePopup(false)">mdi-close</v-icon>
      </div>
      <div class="d-flex justify-center flex-column pl-4 pr-4">
        <div class="text-h6 font-weight-bold text-center">결제를 취소하시겠어요?</div>
        <div class="pt-2 pb-8">결제를 취소하시면 주문이 취소됩니다.</div>
      </div>
      <div class="d-flex justify-center flex-column pl-4 pr-4 pb-6">
        <v-btn @click="closeLeavePopup(true)"
               color="secondary"
               class="text-body-1 font-weight-bold pa-6"
               block rounded>결제 취소하기
        </v-btn>
        <div @click="closeLeavePopup(false)"
             class="text-subtitle-2 text-decoration-underline pt-4 hover-cursor-pointer">닫기
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'OrderCancelBottomSheet',
  data: () => ({
    leavePopup: false
  }),
  created () {
    this.$parent.$on('showLeavePopup', this.showLeavePopup)
  },
  methods: {
    showLeavePopup () {
      this.leavePopup = true
    },
    closeLeavePopup (req) {
      this.leavePopup = false
      if (req) {
        this.$parent.$emit('cancelRequestEmit', true)
      }
    }
  }
}
</script>

<style scoped>

</style>
