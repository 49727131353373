<template>
  <div class="this-is-purchase pt-6 pb-6">
    <div class="pl-4 pr-4 pb-2">
      <span class="text-h6 font-weight-bold">주문/결제 정보</span>
    </div>
    <!-- 상품 정보 -->
    <v-divider></v-divider>
    <ProductInfoCard v-if="productInfo && selectedOption"
                     :origin-price="selectedOption.originPrice"
                     :sale-price="selectedOption.optionPrice"
                     :name="productInfo.name" :option-name="selectedOption.selection"
                     :thumbnail="getMainThumbnail(productInfo)"/>
    <!-- 결제 금액 -->
    <v-divider></v-divider>
    <div class="pl-4 pr-4 mt-0">
      <div class="purchase-price-area d-flex align-center flex-fill">
        <span class="flex-grow-1 flex-shrink-0 text-body-1 font-weight-bold">최종 결제 금액</span>
        <span class="free-shipping flex-grow-0 flex-shrink-0 text-caption pa-1 mt-2 mb-2">무료 배송</span>
        <span class="flex-grow-0 flex-shrink-0 text-body-1 font-weight-bold ml-2">{{
            this.$toMoney(selectedOption.optionPrice)
          }}원</span>
      </div>
    </div>
    <!-- 주문자 정보 -->
    <v-divider></v-divider>
    <div class="pl-4 pr-4 mt-6 mb-3">
      <span class="text-h6 font-weight-bold">주문자 정보</span>
    </div>
    <v-form ref="form" v-model="valid">
      <div class="pl-4 pr-4">
        <v-text-field outlined v-model="userInputData.name" :rules="userInputData.nameRules" label="이름(실명)"
                      required></v-text-field>
        <v-text-field outlined v-model="userInputData.phoneNumber" :rules="userInputData.phoneNumberRules" label="휴대폰번호"
                      required></v-text-field>
        <v-text-field outlined v-model="userInputData.email" :rules="userInputData.emailRules" label="이메일 주소"
                      required></v-text-field>
      </div>
      <!-- 주문자 정보 -->
      <div class="pl-4 pr-4 mt-3">
        <span class="text-h6 font-weight-bold">배송지 정보</span>
        <AddressSearchBottomSheet @addressUpdated="updateAddress"/>
        <div>
          <v-text-field outlined v-model="userInputData.address" label="주소" disabled required :rules="userInputData.addressRules"></v-text-field>
          <v-text-field outlined v-model="userInputData.detailAddress" label="상세 주소"></v-text-field>
        </div>
      </div>
    </v-form>
    <!-- -->
    <div class="d-flex align-center justify-center">
      <span class="text-body-1 font-weight-medium">결제 시, </span>
      <v-btn text class="text-decoration-underline pl-2 pr-0 text-body-1 blue--text font-weight-bold"
             @click.stop="showPrivacyPopup">개인정보 처리방침
      </v-btn>
      <span class="text-body-1 font-weight-medium">에 대해 동의합니다.</span>
    </div>
    <PrivacyBottomSheet/>

    <div class="pa-4">
      <v-btn @click="requestPurchase" class="text-body-1 font-weight-bold pa-6"
             color="primary"
             block
             elevation="2"
             :disabled="!valid"
             rounded>
        동의 후 카드 결제하기
      </v-btn>
    </div>
    <LeaveBottomSheet template-type="purchaseApply"/>
  </div>
</template>

<script>
import ProductInfoCard from '../../components/product/ProductInfoCard'
import PrivacyBottomSheet from '../../components/common/popup/bottom-sheet/PrivacyBottomSheet'
import AddressSearchBottomSheet from '../../components/common/popup/bottom-sheet/AddressSearchBottomSheet'
import LeaveBottomSheet from '../../components/common/popup/bottom-sheet/LeaveBottomSheet'

export default {
  name: 'PurchaseApply',
  components: {
    AddressSearchBottomSheet,
    PrivacyBottomSheet,
    ProductInfoCard,
    LeaveBottomSheet
  },
  data: () => ({
    routeTo: '',
    canLeaveSite: false,
    productInfo: {},
    selectedOption: {},
    valid: false,
    userInputData: {
      name: '',
      nameRules: [
        v => !!v || '배송 받는 분 이름을 입력하세요.',
        v => /^[가-힣]+$/.test(v) || '한글만 입력해 주세요'
      ],
      email: '',
      emailRules: [
        v => !!v || '이메일을 입력해주세요.',
        v => /.+@.+\..+/.test(v) || '정확히 입력해주세요.'
      ],
      phoneNumber: '',
      phoneNumberRules: [
        v => !!v || '휴대폰번호를 입력해주세요.',
        v => /^[0-9]+$/.test(v) || '숫자만 입력해주세요.(\'-\'제외)'
      ],
      address: '',
      addressRules: [
        v => !!v || '배송지를 입력해주세요.'
      ],
      detailAddress: '',
      postCode: ''
    }
  }),
  computed: {
    joinedAddress: function () {
      return [this.userInputData.address, this.userInputData.detailAddress].join(' ')
    }
  },
  mounted () {
    // window.addEventListener('beforeunload', this.beforeUnloadHandler)
    window.IMP.init('imp08711377')
    console.log('window.IMP', window.IMP)
  },
  created () {
    console.log('this.$route.params', this.$route.params)
    this.selectedOption = this.$route.params.selectedOption
    this.productInfo = this.$route.params.productInfo
    this.$on('addressUpdated', this.updateAddress)
    // this.$on('updateCanLeaveSite', this.updateCanLeaveSite)
    const optionInfo = this.productInfo.productOptions[0]
    // 선택된 옵션 가격으로 price 변경
    this.$sendGAEvent('begin_checkout', {
      item_id: this.productInfo.id,
      item_name: this.productInfo.name,
      item_variant: this.selectedOption.selection,
      currency: this.productInfo.currency,
      price: this.selectedOption.optionPrice,
      quantity: 1,
      optionId: optionInfo.id,
      optionSelectionId: this.selectedOption.id
    })
  },
  beforeDestroy () {
    // window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  },
  // beforeRouteLeave (to, from, next) {
  //   if (this.canLeaveSite) {
  //     next()
  //   } else {
  //     this.routeTo = to
  //     this.$emit('showLeavePopup')
  //     next(false)
  //   }
  // },
  methods: {
    updateCanLeaveSite (canLeaveSite) {
      this.canLeaveSite = canLeaveSite
      if (this.canLeaveSite && this.routeTo) {
        this.$router.replace(this.routeTo)
      }
    },
    // beforeUnloadHandler (event) {
    //   if (this.canLeaveSite) return
    //   event.preventDefault()
    //   event.returnValue = ''
    // },
    updateAddress (newAddress) {
      this.userInputData.address = newAddress.extraAddress === '' ? newAddress.address : newAddress.address + newAddress.extraAddress
      this.userInputData.postCode = newAddress.postCode
    },
    showPrivacyPopup () {
      this.$emit('showPrivacyBottomSheet')
    },
    requestPurchase () {
      if (this.valid) {
        // const optionInfo = this.productInfo.productOptions[0]
        console.log('this.productInfo', this.productInfo)
        console.log('this.userInputData', this.userInputData)
        console.log('this.$router', this.$router)
        // const purchaseApplyData = {
        //   productId: this.productInfo.id,
        //   optionId: optionInfo.id,
        //   optionSelectionId: this.selectedOption.id,
        //   netAmount: this.selectedOption.optionPrice,
        //   buyerName: this.userInputData.name,
        //   buyerTel: this.userInputData.phoneNumber,
        //   buyerEmail: this.userInputData.email,
        //   buyerAddr: this.joinedAddress,
        //   buyerPostCode: this.userInputData.postCode
        // }
        // this.$sendGAEvent('CO-BUYING_PURCHASE', {
        //   productId: this.productInfo.id,
        //   optionId: optionInfo.id,
        //   optionSelectionId: this.selectedOption.id,
        //   optionSelectionName: this.selectedOption.selection,
        //   name: this.productInfo.name,
        //   buyerEmail: this.userInputData.email
        // })
        // this._postPurchaseRequest(purchaseApplyData).then((response) => {
        //   if (response.data.requestUrl) {
        //     // this.$router.push()
        //     this.updateCanLeaveSite(true)
        //     window.location.replace(response.data.requestUrl)
        //   } else {
        //     if ((response.data.errors && response.data.errors.length > 0) || response.data.message.length > 0) {
        //       alert(response.data.message)
        //     }
        //   }
        // }).catch((error) => {
        //   console.log(error)
        // })
        var router = this.$router
        window.IMP.request_pay({
          pg: 'html5_inicis.INIpayTest',
          pay_method: 'card',
          merchant_uid: 'merchant_' + new Date().getTime(), // 상점에서 관리하는 주문 번호를 전달
          name: this.productInfo.name,
          amount: 150,
          buyer_email: this.userInputData.email,
          buyer_name: this.userInputData.name,
          buyer_tel: this.userInputData.phoneNumber,
          buyer_addr: this.joinedAddress,
          buyer_postcode: this.userInputData.postCode,
          m_redirect_url: 'https://celeb.08liter.com/product/onlif' // 예: https://www.my-service.com/payments/complete/mobile
        }, (rsp) => { // callback 로직
          var msg
          if (rsp.success) {
            msg = '결제가 완료되었습니다.'
            msg += '\n고유ID : ' + rsp.imp_uid
            msg += '\n상점 거래ID : ' + rsp.merchant_uid
            msg += '\n결제 금액 : ' + rsp.paid_amount
            msg += '카드 승인번호 : ' + rsp.apply_num
            alert(msg)
            router.push({ path: '/product/onlif' })
          } else {
            msg = '결제에 실패하였습니다.'
            msg += '에러내용 : ' + rsp.error_msg
            alert(msg)
          }
        })
      }
    },
    _postPurchaseRequest (data) {
      return this.axios.post(this.API_PATH + '/nonmember/purchase/product', data)
    },
    getMainThumbnail (info) {
      // if (info.productImageList.length > 0) {
      //   return info.productImageList.find(image => { return image.imageCode === '0301' }).imageURI + '320'
      // } else {
      //   return require('@/assets/images/icon/logo.svg')
      // }
      return require('../../assets/images/onlif/230203온리프_랜딩_공팔리터(배너).jpg')
      // return require('@/assets/images/icon/logo.svg')
    }
  }
}
</script>

<style scoped>
.purchase-price-area {
  background-color: #FAFAFA;
}

.free-shipping {
  background-color: #EEEEEE;
  border-radius: 10px;
}
</style>
