<template>
  <v-bottom-sheet v-model="leavePopup" inset max-width="500">
    <div v-if="templateType === 'productDetail'">
      <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
        <div class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-icon color="black" class="pa-4" @click="closeLeavePopup(false)">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4">
          <div class="text-h6 font-weight-bold text-center">정말 나가시겠어요?</div>
          <div class="pt-2 pb-8">나가시면 상품이 품절 될 수 있어요!</div>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4 pb-6">
          <v-btn @click="closeLeavePopup(false)"
                 color="secondary"
                 class="text-body-1 font-weight-bold pa-6"
                 block rounded>아니요, 계속 구매할게요.
          </v-btn>
          <div @click="closeLeavePopup(true)"
               class="text-subtitle-2 text-decoration-underline pt-4 hover-cursor-pointer">네,
            나갈게요
          </div>
        </div>
      </v-sheet>
    </div>
    <div v-else-if="templateType === 'purchaseApply'">
      <v-sheet class="text-center rounded-tl-lg rounded-tr-lg">
        <div class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-icon color="black" class="pa-4" @click="closeLeavePopup(false)">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4">
          <div class="text-h6 font-weight-bold text-center">결제를 취소하시겠어요?</div>
          <div class="pt-2 pb-8">취소하시면, 입력하신 정보가 사라져요.</div>
        </div>
        <div class="d-flex justify-center flex-column pl-4 pr-4 pb-6">
          <v-btn @click="closeLeavePopup(false)"
                 color="secondary"
                 class="text-body-1 font-weight-bold pa-6"
                 block rounded>아니요, 계속 결제할게요
          </v-btn>
          <div @click="closeLeavePopup(true)"
               class="text-subtitle-2 text-decoration-underline pt-4 hover-cursor-pointer">
            네, 취소할게요
          </div>
        </div>
      </v-sheet>
    </div>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'LeaveBottomSheet',
  props: {
    templateType: {
      type: String,
      require: true
    }
  },
  data: () => ({
    leavePopup: false
  }),
  created () {
    this.$parent.$on('showLeavePopup', this.showLeavePopup)
  },
  methods: {
    showLeavePopup () {
      this.leavePopup = true
    },
    closeLeavePopup (canLeaveSite) {
      this.leavePopup = false
      this.$parent.$emit('updateCanLeaveSite', canLeaveSite)
    }
  }
}
</script>

<style>
.hover-cursor-pointer {
  cursor: pointer;
}
</style>
